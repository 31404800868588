define("data-marketplace-ui/templates/components/header-link", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "FJ+IPEss",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,[37,2],null,[[\"current-when\",\"class\",\"activeClass\",\"query\"],[[32,0,[\"_isCurrent\"]],[32,0,[\"_class\"]],[32,0,[\"activeClass\"]],[30,[36,1],null,[[\"sort\",\"searchTerm\"],[[32,0,[\"_sortQp\"]],[32,0,[\"searchTerm\"]]]]]]],[[\"default\"],[{\"statements\":[[2,\"  \"],[18,1,null],[2,\"\\n\\n\"],[6,[37,0],[[32,0,[\"_isCurrent\"]]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[8,\"svg-arrow-down\",[[24,\"width\",\"14\"],[24,\"height\",\"14\"],[24,0,\"ember-tableish__header-link__icon\"]],[[],[]],null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"if\",\"-hash\",\"link-to\"]}",
    "meta": {
      "moduleName": "data-marketplace-ui/templates/components/header-link.hbs"
    }
  });

  _exports.default = _default;
});