define("data-marketplace-ui/components/csv-file-card", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['csv-file-card'],
    intl: Ember.inject.service(),
    file: null,
    vinCount: Ember.computed.alias('file.meta.vinCount'),
    errors: Ember.computed.alias('file.meta.errors'),
    // TODO: surface total # of duplicates/occurences for the UI, see template
    duplicates: Ember.computed.alias('file.meta.duplicates'),
    isParsing: Ember.computed('file', {
      get: function get() {
        // meta is only available on validated files
        return !this.file.meta;
      }
    }),
    hasErrors: Ember.computed('isParsing', {
      get: function get() {
        return this.errors && this.errors.length;
      }
    }),
    fileName: Ember.computed('isParsing', {
      get: function get() {
        return this.isParsing ? this.file.name : this.file.fileName;
      }
    }),
    // TODO: this isn't very accurate, but the file size doesn't really matter
    // since we're uploading a list of VINs and stop caring about the file after we parse it.
    // May need to revisit this later or remove altogether.
    fileSize: Ember.computed('isParsing', {
      get: function get() {
        var size = this.isParsing ? this.file.size : this.file.fileSize;

        if (size < 1024) {
          return "".concat(size, " B");
        }

        var unit = size < 1024 * 1024 ? 'KB' : 'MB';
        return "".concat(Math.round(size / 1024), " ").concat(unit);
      }
    }),
    totalVINs: Ember.computed('isParsing', {
      get: function get() {
        return this.isParsing ? '---' : this.vinCount.total;
      }
    }),
    rowClasses: Ember.computed('isParsing', {
      get: function get() {
        var classes = ['csv-list__row'];

        if (this.isParsing) {
          classes.push('csv-list__row--loading');
        } else if (this.hasErrors) {
          classes.push('csv-list__row--error');
        }

        return classes.join(' ');
      }
    })
  });

  _exports.default = _default;
});