define("data-marketplace-ui/serializers/service", ["exports", "data-marketplace-ui/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    normalizeFindAllResponse: function normalizeFindAllResponse(store, primaryModelClass, payload, id, requestType) {
      var products = payload.products.filter(function (product) {
        return product.uiEnabled;
      }).map(function (product) {
        return {
          id: product.productId,
          type: 'service',
          attributes: Object.assign({}, product, {
            description: product.description
          })
        };
      });
      var serializedData = {
        data: products
      };
      return this._super(store, primaryModelClass, serializedData, id, requestType);
    }
  });

  _exports.default = _default;
});