define("data-marketplace-ui/components/svg-upload", ["exports", "@loom/ember-svgs/components/svg"], function (_exports, _svg) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _svg.default.extend({
    width: 50,
    height: 34,
    viewBox: '0 0 50 34'
  });

  _exports.default = _default;
});