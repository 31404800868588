define("data-marketplace-ui/routes/subscribers", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // import { set } from '@ember/object';
  var _default = Ember.Route.extend({
    currentUser: Ember.inject.service(),
    beforeModel: function beforeModel() {
      // Clear this data from the store after we stop viewing a subscriber
      this.store.unloadAll('vehicles-count');
      this.store.unloadAll('order');
      this.currentUser.stopImpersonation();
    },
    model: function model() {
      return this.store.findAll('subscriber');
    },
    // afterModel() {
    //   const subscribers = this.store.peekAll('subscriber');
    //   subscribers.toArray();
    //   for (let i = 0; i < subscribers.length; i++) {
    //     set(
    //       subscribers.objectAt(i),
    //       'vehicleCounts',
    //       this.store.findRecord('vehicles-count', subscribers.objectAt(i).id)
    //     );
    //   }
    // },
    deactivate: function deactivate() {
      this.store.unloadAll('vehicles-count'); // Used elsewhere with different ids, need to unload
    }
  });

  _exports.default = _default;
});