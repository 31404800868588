define("data-marketplace-ui/mirage/factories/vehicle", ["exports", "ember-cli-mirage", "lodash", "data-marketplace-ui/mirage/helpers/rand", "faker"], function (_exports, _emberCliMirage, _lodash, _rand, _faker) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var DIGITS = _lodash.default.times(9, function (i) {
    return String(i);
  });

  var VALID_VIN_CHARS = DIGITS.concat(_lodash.default.times(26, function (i) {
    return String.fromCharCode(65 + i);
  })).filter(function (char) {
    return char !== 'I' && char !== 'O' && char !== 'Q';
  });
  var VALID_VIN_NINTH_CHAR = DIGITS.concat('X');

  var _default = _emberCliMirage.Factory.extend({
    enrolled: (0, _emberCliMirage.trait)({
      status: 'completed'
    }),
    createdAt: function createdAt() {
      return _faker.default.date.recent(14).toISOString();
    },
    lastModifiedAt: function lastModifiedAt() {
      return _faker.default.date.recent(5).toISOString();
    },
    status: function status() {
      return _faker.default.random.arrayElement(['completed', 'pending', 'error']);
    },
    tags: function tags(i) {
      return "v0".concat(i + 1);
    },
    make: function make() {
      return 'Toyota';
    },
    model: function model() {
      return _faker.default.random.arrayElement(['Prius', 'Corolla', 'Camry', 'Sienna']);
    },
    vin: function vin() {
      return _lodash.default.times(8, function () {
        return VALID_VIN_CHARS[(0, _rand.randomInt)(0, VALID_VIN_CHARS.length)];
      }).concat(VALID_VIN_NINTH_CHAR[(0, _rand.randomInt)(0, VALID_VIN_NINTH_CHAR.length)], _lodash.default.times(2, function () {
        return VALID_VIN_CHARS[(0, _rand.randomInt)(0, VALID_VIN_CHARS.length)];
      }), _lodash.default.times(6, function () {
        return DIGITS[(0, _rand.randomInt)(0, DIGITS.length)];
      })).join('');
    },
    afterCreate: function afterCreate(vehicle, server) {
      var services = server.db.services;
      vehicle.update({
        subscriptions: services.map(function (service) {
          return {
            product: service.label,
            productId: service.id,
            status: _faker.default.random.arrayElement(['Active', 'Inactive', 'Pending'])
          };
        })
      });
    }
  });

  _exports.default = _default;
});