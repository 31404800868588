define("data-marketplace-ui/helpers/ember-power-calendar-day-classes", ["exports", "ember-power-calendar/helpers/ember-power-calendar-day-classes"], function (_exports, _emberPowerCalendarDayClasses) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _emberPowerCalendarDayClasses.default;
    }
  });
  Object.defineProperty(_exports, "emberPowerCalendarDayClasses", {
    enumerable: true,
    get: function get() {
      return _emberPowerCalendarDayClasses.emberPowerCalendarDayClasses;
    }
  });
});