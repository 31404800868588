define("data-marketplace-ui/adapters/vehicles-count", ["exports", "data-marketplace-ui/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    pathForType: function pathForType(type) {
      return Ember.String.dasherize(type);
    },
    urlForFindRecord: function urlForFindRecord(id) {
      return "".concat(this.host, "/mgmt/v2/ui/subscribers/").concat(id, "/vehicles-count");
    }
  });

  _exports.default = _default;
});