define("data-marketplace-ui/controllers/overview", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // eslint-disable-next-line ember/alias-model-in-controller
  var _default = Ember.Controller.extend({
    queryParams: ['offset'],
    session: Ember.inject.service(),
    mage: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    intl: Ember.inject.service(),
    orders: Ember.computed('model.orders.[]', {
      get: function get() {
        return this.model.orders.slice(0, 3);
      }
    }),
    isLoadingOrders: Ember.computed('orders.[]', {
      get: function get() {
        return !this.model.orders.isFulfilled && !this.model.orders.isRejected;
      }
    }),
    welcomeMessage: Ember.computed('currentUser.isImpersonating', {
      get: function get() {
        if (this.currentUser.isImpersonating) {
          return {
            message: this.intl.t('overview.welcome-to'),
            name: this.currentUser.impersonatedSubscriber.name
          };
        }

        return {
          message: this.intl.t('overview.welcome'),
          name: this.currentUser.username
        };
      }
    }),

    /**
     * @private
     * @description Determines whether the user sees the dashboard or "get started"
     *  content.
     * @type {boolean}
     */
    isFirstTime: true // TODO: compute this based on whether or not there are any orders?

  });

  _exports.default = _default;
});