define("data-marketplace-ui/components/app-header-section", ["exports", "@loom/ember-chrome/components/app-header-section"], function (_exports, _appHeaderSection) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _appHeaderSection.default;
    }
  });
});