define("data-marketplace-ui/controllers/passwords", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // import { inject as service } from '@ember/service';
  // import { task } from 'ember-concurrency';
  var _default = Ember.Controller.extend({
    email: null
  });

  _exports.default = _default;
});