define("data-marketplace-ui/routes/sign-in", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model(params) {
      if (params.reset) {
        var controller = this.controllerFor('sign-in');
        var passwordsController = this.controllerFor('passwords');
        Ember.setProperties(controller, {
          showResetConfirmation: true,
          email: passwordsController.email
        });
      }
    }
  });

  _exports.default = _default;
});