define("data-marketplace-ui/app", ["exports", "data-marketplace-ui/resolver", "ember-load-initializers", "data-marketplace-ui/config/environment", "@aws-amplify/auth"], function (_exports, _resolver, _emberLoadInitializers, _environment, _auth) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  _auth.Auth.configure(_environment.default.amplify);

  var App = Ember.Application.extend({
    modulePrefix: _environment.default.modulePrefix,
    podModulePrefix: _environment.default.podModulePrefix,
    Resolver: _resolver.default
  });
  (0, _emberLoadInitializers.default)(App, _environment.default.modulePrefix);
  var _default = App;
  _exports.default = _default;
});