define("data-marketplace-ui/adapters/application", ["exports", "ember-data", "ember-fetch/mixins/adapter-fetch", "ember-simple-auth/mixins/data-adapter-mixin", "data-marketplace-ui/config/environment"], function (_exports, _emberData, _adapterFetch, _dataAdapterMixin, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // TODO: Remove ember-fetch as its no longer needed, see: https://github.com/ember-cli/ember-fetch#use-with-ember-data
  var RESTAdapter = _emberData.default.RESTAdapter;
  var useMirage = ['test', 'local'].includes(_environment.default.environment);

  var _default = RESTAdapter.extend(_adapterFetch.default, _dataAdapterMixin.default, {
    session: Ember.inject.service(),
    amplify: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    host: Ember.computed('ENV.ember-cli-mirage.enabled', {
      get: function get() {
        if (useMirage) {
          return '';
        }

        return "".concat(_environment.default.APP.host, ".toyotadatasolutions.com");
      }
    }),
    token: Ember.computed.readOnly('amplify.user.user.signInUserSession.accessToken.jwtToken'),
    idToken: Ember.computed.readOnly('amplify.user.user.signInUserSession.idToken.jwtToken'),
    apikey: _environment.default.APP.apikey,
    namespace: Ember.computed('currentUser.custom:subscriberid', {
      get: function get() {
        var subscriberId = this.currentUser['custom:subscriberid'];
        return "mgmt/v2/ui/subscribers/".concat(subscriberId);
      }
    }),
    headers: Ember.computed({
      get: function get() {
        return {
          'x-id-token': this.token,
          'x-user-id-token': this.idToken,
          apikey: this.apikey
        };
      }
    })
  });

  _exports.default = _default;
});