define("data-marketplace-ui/templates/components/svg-upload", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "MHuTfO4X",
    "block": "{\"symbols\":[],\"statements\":[[10,\"path\"],[14,\"d\",\"M41.2523 14.5195C40.1547 6.39375 33.2758 0.34375 25 0.34375C17.8813 0.34375 11.6906 4.81875 9.44687 11.5297C4.39687 12.5922 0.78125 17.0125 0.78125 22.2188C0.78125 27.918 5.21641 32.7211 10.9375 33.1562H24.2188V19.4172L16.9586 26.6773C16.6531 26.9828 16.1594 26.9828 15.8539 26.6773C15.5484 26.3719 15.5484 25.8781 15.8539 25.5727L24.4469 16.9797C24.75 16.6758 25.2492 16.6758 25.5523 16.9797L34.1453 25.5727C34.4508 25.8781 34.4508 26.3719 34.1453 26.6773C33.8398 26.9828 33.3461 26.9828 33.0406 26.6773L25.7812 19.4172V33.1562H40.625C40.6484 33.1562 40.6719 33.1555 40.6953 33.1531C45.475 32.7219 49.2188 28.6055 49.2188 23.7812C49.2188 19.1547 45.7688 15.2047 41.2523 14.5195Z\"],[15,\"fill\",[30,[36,0],[\"background\",\"dark\"],null]],[12],[13],[2,\"\\n\\n\"]],\"hasEval\":false,\"upvars\":[\"color\"]}",
    "meta": {
      "moduleName": "data-marketplace-ui/templates/components/svg-upload.hbs"
    }
  });

  _exports.default = _default;
});