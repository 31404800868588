define("data-marketplace-ui/components/enrollment-wizard/select-services", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['wizard__step wizard__step--select-services', 'flex flex-row'],
    classNameBindings: ['active::animate-from'],
    uploadDisabled: Ember.computed('selectedServices.[]', function () {
      return Ember.isEmpty(this.selectedServices);
    }),
    manyServices: Ember.computed('services.[]', function () {
      return this.services.length > 6;
    })
  });

  _exports.default = _default;
});