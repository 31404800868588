define("data-marketplace-ui/templates/components/service", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "9asQPKgM",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"service-box__top\"],[12],[2,\"\\n\\n\"],[6,[37,2],[[35,1]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"service-box__amount\"],[12],[2,\"\\n      \"],[1,[34,1]],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"service-box__coming-soon-text\"],[12],[2,\"\\n      \"],[1,[30,[36,0],[\"prototype.coming-soon\"],null]],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n  \"],[10,\"div\"],[14,0,\"service-icon__placeholder service-icon__placeholder--large\"],[12],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[10,\"div\"],[14,0,\"service-box__body\"],[12],[2,\"\\n  \"],[8,\"title-text\",[],[[\"@text\",\"@size\",\"@weight\"],[[34,3],\"title-1\",\"semibold\"]],null],[2,\"\\n\\n  \"],[8,\"body-text\",[[24,0,\"my--default\"]],[[\"@text\"],[[34,4]]],null],[2,\"\\n\\n  \"],[8,\"button-basic\",[[24,\"data-contact-sales\",\"true\"]],[[\"@text\",\"@palette\",\"@shade\",\"@fill\"],[\"Learn More\",[34,5],[34,6],\"outline\"]],null],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\",\"vehicleNumber\",\"if\",\"title\",\"body\",\"palette\",\"shade\"]}",
    "meta": {
      "moduleName": "data-marketplace-ui/templates/components/service.hbs"
    }
  });

  _exports.default = _default;
});