define("data-marketplace-ui/controllers/passwords/forgot", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // eslint-disable-next-line ember/alias-model-in-controller
  var _default = Ember.Controller.extend({
    passwords: Ember.inject.controller()
  });

  _exports.default = _default;
});