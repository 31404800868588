define("data-marketplace-ui/templates/components/svg/svg-event-notification", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "KCc4LFSA",
    "block": "{\"symbols\":[],\"statements\":[[10,\"g\"],[14,\"transform\",\"translate(-82 -82)\"],[12],[2,\"\\n  \"],[10,\"path\"],[14,\"d\",\"M102.742 106.727a2.806 2.806 0 0 1-5.498 0zm-2.75-15.757a1.122 1.122 0 0 1 1.11 1.28c2.618.529 4.5 2.829 4.5 5.5v4.489l2.245 2.245h-.002v1.122H92.136v-1.122l2.245-2.245v-4.49c0-2.67 1.882-4.97 4.5-5.5a1.122 1.122 0 0 1 1.111-1.28z\"],[12],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "data-marketplace-ui/templates/components/svg/svg-event-notification.hbs"
    }
  });

  _exports.default = _default;
});