define("data-marketplace-ui/mirage/factories/product", ["exports", "ember-cli-mirage"], function (_exports, _emberCliMirage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCliMirage.Factory.extend({
    description: function description() {
      return "This is a generic description of the ".concat(this.label, " service.");
    }
  });

  _exports.default = _default;
});