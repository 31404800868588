define("data-marketplace-ui/components/sign-in-form", ["exports", "@loom/ember-auth/components/sign-in-form"], function (_exports, _signInForm) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _signInForm.default;
    }
  });
});