define("data-marketplace-ui/serializers/vehicles-count", ["exports", "data-marketplace-ui/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    normalizeFindAllResponse: function normalizeFindAllResponse(store, primaryModelClass, payload, id, requestType) {
      var jsonApi = {
        data: [{
          id: '1',
          type: 'vehiclesCount',
          attributes: payload.vehicleCounts
        }]
      };
      return this._super(store, primaryModelClass, jsonApi, id, requestType);
    },
    normalizeFindRecordResponse: function normalizeFindRecordResponse(store, primaryModelClass, payload, id, requestType) {
      var jsonApi = {
        data: {
          id: id,
          type: 'vehiclesCount',
          attributes: payload.vehicleCounts
        }
      };
      return this._super(store, primaryModelClass, jsonApi, id, requestType);
    }
  });

  _exports.default = _default;
});