define("data-marketplace-ui/routes/vehicles", ["exports", "data-marketplace-ui/utils/extended-infinity-model"], function (_exports, _extendedInfinityModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    infinity: Ember.inject.service(),
    queryParams: {
      sort: {
        refreshModel: true
      },
      status: {
        refreshModel: true
      },
      startDate: {
        refreshModel: true
      },
      endDate: {
        refreshModel: true
      }
    },
    model: function model(_ref, transition) {
      var sort = _ref.sort,
          status = _ref.status,
          offset = _ref.offset,
          startDate = _ref.startDate,
          endDate = _ref.endDate;
      var _transition$to$queryP = transition.to.queryParams.searchTerm,
          searchTerm = _transition$to$queryP === void 0 ? null : _transition$to$queryP;
      return {
        vehicles: this.infinity.model('vehicle', {
          sort: sort,
          status: status,
          offset: offset,
          searchTerm: searchTerm,
          startDate: startDate,
          endDate: endDate,
          perPageParam: null,
          pageParam: null
        }, _extendedInfinityModel.default)
      };
    },
    deactivate: function deactivate() {
      Ember.set(this.controller, 'status', 'completed');
      this.store.unloadAll('service');
    }
  });

  _exports.default = _default;
});