define("data-marketplace-ui/models/subscriber-request-detail", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo;
  /**
   * TODO: map backend attrs where necessary
   * -------------------------
   * request_id
   * vin (vehicle_id)
   * product_subscription_id
   * action_type -> actionType
   * status
   * error_code -> errorCode
   * --- may not need the things below this line for the UI --
   * created_time -> createdAt
   * last_modified_time -> lastModifiedAt
   * created_by -> createdBy
   * modified_by -> modifiedBy
   */

  var _default = Model.extend({
    request: belongsTo('subscriber-request'),
    vehicle: belongsTo('vehicle'),
    productSubscription: belongsTo('product-subscription'),
    actionType: attr('string'),
    status: attr('string'),
    errorCode: attr('number')
  });

  _exports.default = _default;
});