define("data-marketplace-ui/components/service-card", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['service-card'],
    classNameBindings: ['selected:service-card--selected'],
    attributeBindings: ['label:data-test-service-name'],
    label: Ember.computed.reads('service.label'),
    click: function click() {
      this.onSelect();
    }
  });

  _exports.default = _default;
});