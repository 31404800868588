define("data-marketplace-ui/templates/components/svg-checkmark", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "qyVNOMbY",
    "block": "{\"symbols\":[],\"statements\":[[10,\"polyline\"],[14,\"fill\",\"none\"],[14,\"stroke\",\"#fff\"],[14,\"stroke-width\",\"1.25\"],[14,\"stroke-linecap\",\"round\"],[14,\"stroke-linejoin\",\"round\"],[14,\"stroke-miterlimit\",\"10\"],[14,\"points\",\"2.7,6.4 5,8.7 8.9,3.2 \"],[12],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "data-marketplace-ui/templates/components/svg-checkmark.hbs"
    }
  });

  _exports.default = _default;
});