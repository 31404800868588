define("data-marketplace-ui/controllers/subscribers", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    currentUser: Ember.inject.service(),
    actions: {
      impersonateSubscriber: function impersonateSubscriber(subscriber) {
        this.currentUser.impersonateSubscriber(subscriber);
      }
    }
  });

  _exports.default = _default;
});