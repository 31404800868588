define("data-marketplace-ui/models/subscriber", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr,
      hasMany = _emberData.default.hasMany;
  /**
   * TODO: map backend attrs where necessary
   * -------------------------
   * name
   * type
   * email_address
   * --- may not need the things below this line for the UI --
   * owner
   * created_time -> createdAt
   * last_modified_time -> lastModifiedAt
   * created_by -> createdBy
   * modified_by -> modifiedBy
   */

  var _default = Model.extend({
    name: attr('string'),
    type: attr('string'),
    emailAddress: attr('string'),
    products: attr('raw'),
    vehicleCounts: attr('raw'),
    activeVehicles: attr('raw'),
    users: attr('raw'),
    region: attr('string'),
    createdTimestamp: attr('date'),
    productSubscriptions: hasMany('product-subscription'),
    subscriberRequests: hasMany('subscriber-request')
  });

  _exports.default = _default;
});