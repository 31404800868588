define("data-marketplace-ui/templates/components/drop-target", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "s0bDNvUz",
    "block": "{\"symbols\":[\"@onDrop\"],\"statements\":[[10,\"div\"],[14,0,\"drop-zone\"],[12],[2,\"\\n\"],[6,[37,2],[[32,0,[\"isDragging\"]]],[[\"use\"],[[35,1]]],[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"icon-container\"],[12],[2,\"\\n      \"],[8,\"svg-upload\",[[24,\"width\",\"32\"],[24,\"height\",\"32\"]],[[],[]],null],[2,\"\\n    \"],[13],[2,\"\\n\\n    \"],[8,\"body-text\",[],[[\"@text\",\"@palette\",\"@class\"],[[30,[36,0],[\"forms.drop-here\"],null],\"black\",\"text--subtitle-1\"]],null],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[8,\"body-text\",[[24,0,\"mt--default text--subtitle-1\"]],[[\"@text\"],[[30,[36,0],[\"forms.drag-and-drop\"],null]]],null],[2,\"\\n    \"],[8,\"body-text\",[[24,0,\"color__background--dark text--subtitle-1\"]],[[\"@text\"],[[30,[36,0],[\"forms.csv-files-here\"],null]]],null],[2,\"\\n\\n    \"],[8,\"csv-uploader\",[[24,0,\"my--default btn--filled-black text--subtitle-3 btn--small\"]],[[\"@text\",\"@handleUpload\"],[[30,[36,0],[\"forms.browse\"],null],[32,1]]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\",\"fade\",\"animated-if\"]}",
    "meta": {
      "moduleName": "data-marketplace-ui/templates/components/drop-target.hbs"
    }
  });

  _exports.default = _default;
});