define("data-marketplace-ui/templates/components/learn-more", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "uXdT6TmM",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"learn-box__learn-info max-w--half\"],[12],[2,\"\\n  \"],[8,\"title-text\",[],[[\"@text\",\"@size\",\"@weight\"],[[30,[36,0],[\"learn-more.title\"],null],\"title-2\",\"semibold\"]],null],[2,\"\\n  \"],[8,\"body-text\",[[24,0,\"my--default\"]],[[\"@text\"],[\"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore\\n    et dolore magna aliqua Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua\"]],null],[2,\"\\n\\n\\n  \"],[8,\"button-basic\",[[24,\"data-contact-sales\",\"true\"],[24,0,\"max-w--half\"]],[[\"@text\",\"@palette\",\"@shade\",\"@fill\"],[\"Learn More\",\"primary\",\"light\",\"outline\"]],null],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"flex flex-1 w--full items-center justify-center\"],[12],[2,\"\\n  \"],[10,\"img\"],[15,\"alt\",[30,[36,0],[\"support.contact-sales\"],null]],[14,\"src\",\"https://media.licdn.com/dms/image/C5603AQGX1tlHzJMnhA/profile-displayphoto-shrink_200_200/0?e=1561593600&v=beta&t=8nW5B_bJ00GtOpuKuB6ABTCnhjv7A3QQx1ERBox8brE\"],[12],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"t\"]}",
    "meta": {
      "moduleName": "data-marketplace-ui/templates/components/learn-more.hbs"
    }
  });

  _exports.default = _default;
});