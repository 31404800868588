define("data-marketplace-ui/models/subscriber-request", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo,
      hasMany = _emberData.default.hasMany;
  /**
   * TODO: map backend attrs where necessary
   * -------------------------
   * subscriber_id
   * type
   * file_name -> fileName
   * status
   * --- may not need the things below this line for the UI --
   * created_time -> createdAt
   * last_modified_time -> lastModifiedAt
   * created_by -> createdBy
   * modified_by -> modifiedBy
   */

  var _default = Model.extend({
    subscriber: belongsTo('subscriber'),
    subscriberRequestDetails: hasMany('subscriber-request-detail'),
    type: attr('string'),
    fileName: attr('string'),
    status: attr('string')
  });

  _exports.default = _default;
});