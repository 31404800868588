define("data-marketplace-ui/components/form-field", ["exports", "ember-animated/transitions/fade"], function (_exports, _fade) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @class FormField
   * @description A block component for rendering a label and a form field.
   */
  var _default = Ember.Component.extend({
    classNames: ['ui-form__field'],
    classNameBindings: ['hasErrors:ui-form__field--has-errors'],
    init: function init() {
      this._super.apply(this, arguments);
    },

    /**
     * @public
     * @description The ember-animated transition to use for toggling error
     *  state visibility.
     */
    transition: _fade.default,

    /**
     * @public
     * @description A flag for error message visibility. Actions for toggling this are
     *  yielded in the template to enable things like showing errors `onblur`.
     * @type {boolean}
     */
    showErrors: false,
    hasErrors: Ember.computed('errors.[]', 'showErrors', {
      get: function get() {
        return this.showErrors && !Ember.isEmpty(this.errors);
      }
    })
  });

  _exports.default = _default;
});