define("data-marketplace-ui/components/enrollment-wizard/order-type", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['wizard__step wizard__step--order-type', 'flex flex-row'],
    classNameBindings: ['active::animate-from']
  });

  _exports.default = _default;
});