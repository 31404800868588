define("data-marketplace-ui/templates/components/svg/svg-error", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "RfA6C16E",
    "block": "{\"symbols\":[],\"statements\":[[10,\"svg\"],[14,\"width\",\"32px\"],[14,\"height\",\"32px\"],[14,\"viewBox\",\"0 0 32 32\"],[12],[2,\"\\n  \"],[10,\"path\"],[14,\"d\",\"M14.4761905,20.5714286 L17.5238095,20.5714286 L17.5238095,23.6190476 L14.4761905,23.6190476 L14.4761905,20.5714286 Z M14.4761905,8.38095238 L17.5238095,8.38095238 L17.5238095,17.5238095 L14.4761905,17.5238095 L14.4761905,8.38095238 Z M15.9847619,0.761904762 C7.57333333,0.761904762 0.761904762,7.58857143 0.761904762,16 C0.761904762,24.4114286 7.57333333,31.2380952 15.9847619,31.2380952 C24.4114286,31.2380952 31.2380952,24.4114286 31.2380952,16 C31.2380952,7.58857143 24.4114286,0.761904762 15.9847619,0.761904762 Z M16,28.1904762 C9.2647619,28.1904762 3.80952381,22.7352381 3.80952381,16 C3.80952381,9.2647619 9.2647619,3.80952381 16,3.80952381 C22.7352381,3.80952381 28.1904762,9.2647619 28.1904762,16 C28.1904762,22.7352381 22.7352381,28.1904762 16,28.1904762 Z\"],[12],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "data-marketplace-ui/templates/components/svg/svg-error.hbs"
    }
  });

  _exports.default = _default;
});