define("data-marketplace-ui/components/form-confirmation", ["exports", "ember-animated/transitions/fade"], function (_exports, _fade) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @class FormConfirmation
   * @description A component for building form confirmation states.
   */
  var _default = Ember.Component.extend({
    classNames: ['form-confirmation'],
    classNameBindings: ['show:form-confirmation--visible', 'backdrop:form-confirmation--has-backdrop'],

    /**
     * @public
     * @description Toggles confirmation visibility.
     */
    show: false,

    /**
     * @private
     * @description When enabled, `backdrop` places the confirmation content over a
     *  semi-transparent background allowing the form to show through. Useful in situations
     *  where the confirmation doesn't need to completely cover the form.
     * @type {boolean}
     */
    backdrop: false,

    /**
     * @private
     */
    transition: _fade.default
  });

  _exports.default = _default;
});