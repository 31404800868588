define("data-marketplace-ui/adapters/vehicle", ["exports", "data-marketplace-ui/adapters/application", "fetch"], function (_exports, _application, _fetch) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    urlForFindRecord: function urlForFindRecord() {
      return "".concat(this.host, "/").concat(this.namespace, "/vehicle");
    },

    /**
     * When fetching a single vin, update the headers to include the vin.
     */
    findRecord: function findRecord(store, type, id, snapshot) {
      var updatedHeaders = Object.assign({}, this.headers, {
        vin: id
      });
      Ember.set(this, 'headers', updatedHeaders);
      return this._super(store, type, id, snapshot);
    },
    query: function query(store, type, snapshot) {
      var host = this.host,
          apikey = this.apikey,
          namespace = this.namespace,
          token = this.token;
      /**
       * @shenanigans
       * If the user does a VIN search, submit a POST request to the vehicles endpoint,
       * otherwise, proceed as usual.
       */

      if (snapshot.searchTerm) {
        var sort = snapshot.sort,
            status = snapshot.status,
            offset = snapshot.offset,
            startDate = snapshot.startDate,
            endDate = snapshot.endDate,
            searchTerm = snapshot.searchTerm;
        /**
         * The API expects to receive the VIN (searchTerm) in the body, but wants the rest of the params
         * in the URL
         */

        var paramsForURL = {
          sort: sort,
          status: status,
          offset: offset,
          startDate: startDate,
          endDate: endDate
        };
        var queryParamString = Object.keys(paramsForURL).filter(function (key) {
          return paramsForURL[key];
        }) // don't include params that are null, `startDate` and `endDate` throw validation errors
        .map(function (key) {
          return "".concat(encodeURIComponent(key), "=").concat(encodeURIComponent(paramsForURL[key]));
        }).join('&');
        return (0, _fetch.default)("".concat(host, "/").concat(namespace, "/vehicles?").concat(queryParamString), {
          method: 'POST',
          headers: {
            apikey: apikey,
            'x-id-token': token,
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            searchTerm: searchTerm
          })
        }).then(function (res) {
          return res.json();
        });
      }

      return this._super(store, type, snapshot);
    }
  });

  _exports.default = _default;
});