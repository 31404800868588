define("data-marketplace-ui/utils/pull-content-from-styles", ["exports", "@loom/ember-styles/utils/pull-content-from-styles"], function (_exports, _pullContentFromStyles) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "pullPalettes", {
    enumerable: true,
    get: function get() {
      return _pullContentFromStyles.pullPalettes;
    }
  });
});