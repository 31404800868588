define("data-marketplace-ui/models/vehicle-subscription", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo;
  /**
   * TODO: map backend attrs where necessary
   * -------------------------
   * vin (vehicle id)
   * product_subscription_id
   * status
   * created_time -> createdAt
   * last_modified_time -> lastModifiedAt
   * created_by -> createdBy
   * modified_by -> modifiedBy
   *
   * Not sure if we need the timestamps for these subscriptions yet.
   */

  var _default = Model.extend({
    productSubscription: belongsTo('product-subscription'),
    // vehicle: belongsTo('vehicle'),
    status: attr('string')
  });

  _exports.default = _default;
});