define("data-marketplace-ui/templates/components/form-confirmation/reset-success", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "XmW27z45",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[10,\"div\"],[14,0,\"form-confirmation__content flex flex-column items-center\"],[12],[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"flex flex-1 flex-column items-center\"],[12],[2,\"\\n    \"],[8,\"title-text\",[],[[\"@text\",\"@palette\",\"@paletteModifier\",\"@class\"],[[30,[36,0],[\"forms.reset-password.confirmation.title\"],null],\"white\",\"full\",\"text--section-2 pb--x-small\"]],null],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[18,1,null],[2,\"\\n\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\"]}",
    "meta": {
      "moduleName": "data-marketplace-ui/templates/components/form-confirmation/reset-success.hbs"
    }
  });

  _exports.default = _default;
});