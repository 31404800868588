define("data-marketplace-ui/templates/components/svg-dash", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "69O59WeI",
    "block": "{\"symbols\":[],\"statements\":[[10,\"path\"],[14,\"d\",\"M3,1 L7,1\"],[14,\"stroke\",\"#ffffff\"],[14,\"stroke-width\",\"1.25\"],[12],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "data-marketplace-ui/templates/components/svg-dash.hbs"
    }
  });

  _exports.default = _default;
});