define("data-marketplace-ui/components/ui-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @class UiForm
   * @description A contextual form component.
   */
  var _default = Ember.Component.extend({
    tagName: 'form',
    classNames: ['ui-form'],

    /**
     * @public
     * @description Action to perform on form submission.
     */
    handleSubmit: null,
    submit: function submit(event) {
      event.preventDefault();
      this.handleSubmit();
    }
  });

  _exports.default = _default;
});