define("data-marketplace-ui/components/service-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @private
   * @description Maps products to their svg icon names.
   * The 'list of products' we receive from the orders/vehicles APIs aren't formatted
   * consistently, even though they represent the same products. This map is a catch-all for
   * showing the icons consistently, regardless of which identifier we're using.
   */
  var SERVICES_MAP = {
    Telemetry: 'telemetry',
    'External Streaming': 'telemetry-streaming',
    'Telemetry Streaming': 'telemetry-streaming',
    'Telemetry OnDemand': 'telemetry',
    'Driver Score': 'driver-behavior',
    'Driver Score B2B': 'driver-behavior',
    'Event Notification': 'event-notifications',
    'Collision Detection': 'collision-notifications',
    'Automatic Collision Notification': 'collision-notifications',
    'Non Automatic Collision Notification': 'collision-notifications',
    'Service Warning': 'service-warnings',
    'Service Warnings': 'service-warnings'
  };
  /**
   * @class ServiceIcon
   * @description Renders the icon for a given marketplace product.
   */

  var _default = Ember.Component.extend({
    classNames: ['service-icon flex items-center justify-center'],

    /**
     * @public
     * @description Whether or not the icon is in a 'selected' state. This is only
     *  possible in the Enrollment Wizard at the moment.
     */
    selected: null,

    /**
     * @public
     * @description The id of the product (service)
     */
    serviceId: null,

    /**
     * @public
     * @description Temporarily solution to enable `ServiceIndicators` to leverage
     * this existing work
     */
    service: null,

    /**
     * @private
     * @description Returns the name of the svg icon based on selection state and serviceId.
     */
    iconName: Ember.computed('selected', 'service', function () {
      if (this.selected) {
        return 'check';
      }

      var serviceId = this.serviceId; // See map above for explanation.

      if (this.service) {
        if (this.service.label) {
          serviceId = this.service.label;
        } else if (this.service.product) {
          serviceId = this.service.product;
        } else if (typeof this.service === 'string') {
          serviceId = this.service;
        }
      }

      return SERVICES_MAP[serviceId] || 'generic_service';
    })
  });

  _exports.default = _default;
});