define("data-marketplace-ui/utils/extended-infinity-model", ["exports", "ember-infinity/lib/infinity-model"], function (_exports, _infinityModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Extends `ember-infinity`'s `InfinityModel` to work with how
   * our API handles pagination. For more info on extending the model,
   * see: https://github.com/ember-infinity/ember-infinity#extending-infinitymodel
   */
  var _default = _infinityModel.default.extend({
    canLoadMore: true,
    offset: 0,
    totalRecords: 0,
    buildParams: function buildParams() {
      var params = this._super.apply(this, arguments);

      params.offset = this.offset;
      return params;
    },
    afterInfinityModel: function afterInfinityModel(records) {
      var isVehiclesSearch = this.extraParams.searchTerm;
      Ember.set(this, 'totalRecords', this.totalRecords + records.length);
      Ember.set(this, 'offset', this.totalRecords + 1);
      var results = records;
      /**
       * Because the vehicles search usually returns less than a page of results,
       * the infinity loader is in the viewport and attempts to fetch more records,
       * sometimes returning dups. This short-circuits that.
       */

      if (isVehiclesSearch) {
        results = records.uniq();
      }

      Ember.set(this, 'canLoadMore', results.length > 0);
      return results;
    }
  });

  _exports.default = _default;
});