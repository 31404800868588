define("data-marketplace-ui/controllers/subscriber-details", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    router: Ember.inject.service(),
    subscriber: Ember.computed.alias('model'),
    currentUser: Ember.inject.service(),
    primaryContact: Ember.computed('subscriber.users', function () {
      var primaryContact = this.subscriber.users.find(function (user) {
        return user.primaryContact;
      });
      return primaryContact;
    }),
    actions: {
      closeSidebar: function closeSidebar() {
        this.router.transitionTo('subscribers');
      },
      impersonateSubscriber: function impersonateSubscriber(subscriber) {
        this.currentUser.impersonateSubscriber(subscriber);
      }
    }
  });

  _exports.default = _default;
});