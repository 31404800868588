define("data-marketplace-ui/templates/components/form-confirmation/verification-code", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "eOEM6ym9",
    "block": "{\"symbols\":[\"@email\",\"@resendTask\",\"&default\"],\"statements\":[[10,\"div\"],[14,0,\"form-confirmation__content flex flex-column h--full\"],[12],[2,\"\\n\"],[6,[37,5],[[32,2,[\"isRunning\"]]],[[\"use\"],[[35,4]]],[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"flex flex-1 items-center justify-center\"],[12],[2,\"\\n      \"],[8,\"svg-spinner\",[[24,\"width\",\"64\"],[24,\"height\",\"64\"]],[[],[]],null],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"flex-1\"],[12],[2,\"\\n      \"],[8,\"title-text\",[],[[\"@text\",\"@palette\",\"@paletteModifier\",\"@class\"],[[30,[36,0],[\"forms.forgot-password.confirmation.title\"],null],\"white\",\"full\",\"text--section-2 pb--x-small\"]],null],[2,\"\\n\\n      \"],[10,\"div\"],[14,0,\"form-confirmation__field-review text--subtitle-3 pt--x-small pb--default mb--default\"],[12],[2,\"\\n        \"],[1,[30,[36,1],[\"email\"],[[\"class\"],[\"mr--small ui-form__field-icon--detail\"]]]],[2,\" \"],[1,[32,1]],[2,\"\\n      \"],[13],[2,\"\\n\\n      \"],[8,\"body-text\",[],[[\"@palette\",\"@paletteModifier\",\"@class\"],[\"white\",\"full\",\"text--body-3\"]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[1,[30,[36,0],[\"forms.forgot-password.confirmation.message\"],null]],[2,\"\\n        \"],[11,\"span\"],[24,0,\"font-weight--semibold\"],[24,\"role\",\"button\"],[24,5,\"cursor:pointer\"],[4,[38,3],[[32,0],[30,[36,2],[[32,2]],null]],null],[12],[2,\"\\n          \"],[1,[30,[36,0],[\"forms.forgot-password.resend-code\"],null]],[2,\"\\n        \"],[13],[2,\"\\n      \"]],\"parameters\":[]}]]],[2,\"\\n    \"],[13],[2,\"\\n\\n    \"],[18,3,null],[2,\"\\n\"]],\"parameters\":[]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\",\"svg-jar\",\"perform\",\"action\",\"transition\",\"animated-if\"]}",
    "meta": {
      "moduleName": "data-marketplace-ui/templates/components/form-confirmation/verification-code.hbs"
    }
  });

  _exports.default = _default;
});