define("data-marketplace-ui/mirage/fixtures/vehicles-count", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = [{
    completed: 10,
    pending: 10,
    error: 10
  }];
  _exports.default = _default;
});