define("data-marketplace-ui/translations/en-us", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "@loom": {
      "ember-auth": {
        "actions": {
          "forgot-password": "Forgot Password",
          "return-to-login": "Return to login",
          "sign-in": "Already a user? Sign In Now",
          "sign-up": "Not a user? Sign Up Now"
        },
        "errors": {
          "conflict": "This email is already taken.",
          "failed": "Invalid email/password combination.",
          "unknown": "Unable to complete request. Please try again later.",
          "validation-email": "Please enter a valid email address.",
          "validation-length": "{field} must be at least {length} characters.",
          "validation-presence": "{field} cannot be blank."
        },
        "form": {
          "buttons": {
            "next": "Next",
            "reset-password": "Reset my password",
            "sign-in": "Sign in",
            "sign-up": "Sign Up"
          },
          "labels": {
            "confirm-password": "Confirm Password",
            "email": "Email Address",
            "first-name": "First Name",
            "last-name": "Last Name",
            "name": "Name",
            "new-password": "New Password",
            "password": "Password",
            "phone": "Phone Number",
            "role": "Role",
            "terms-and-conditions": {
              "1": "I agree to the",
              "2": "Terms and Conditions"
            }
          }
        },
        "instructions": {
          "forgot-password": {
            "check-email": "Check <strong>{email}</strong> for instructions on how to reset your password.",
            "email-sent": "Email Sent!"
          },
          "reset-password": {
            "redirect-to-sign-in": "You may now sign in and use your new password."
          }
        }
      },
      "ember-forms": {
        "errors": {
          "accepted": "{description} must be accepted",
          "after": "{description} must be after {after}",
          "before": "{description} must be before {before}",
          "blank": "{description} can't be blank",
          "collection": "{description} must be a collection",
          "confirmation": "{description} doesn't match {on}",
          "date": "{description} must be a valid date",
          "description": "This field",
          "email": "{description} must be a valid email address",
          "empty": "{description} can't be empty",
          "equalTo": "{description} must be equal to {is}",
          "even": "{description} must be even",
          "exclusion": "{description} is reserved",
          "greaterThan": "{description} must be greater than {gt}",
          "greaterThanOrEqualTo": "{description} must be greater than or equal to {gte}",
          "inclusion": "{description} is not included in the list",
          "invalid": "{description} is invalid",
          "lessThan": "{description} must be less than {lt}",
          "lessThanOrEqualTo": "{description} must be less than or equal to {lte}",
          "notANumber": "{description} must be a number",
          "notAnInteger": "{description} must be an integer",
          "odd": "{description} must be odd",
          "otherThan": "{description} must be other than {value}",
          "phone": "{description} must be a valid phone number",
          "positive": "{description} must be positive",
          "present": "{description} must be blank",
          "singular": "{description} can't be a collection",
          "tooLong": "{description} is too long (maximum is {max} characters)",
          "tooShort": "{description} is too short (minimum is {min} characters)",
          "url": "{description} must be a valid url",
          "wrongDateFormat": "{description} must be in the format of {format}",
          "wrongLength": "{description} is the wrong length (should be {is} characters)"
        }
      }
    },
    "actions": {
      "apply": "Apply",
      "cancel": "Cancel",
      "clear": "Clear",
      "discard": "Discard",
      "done": "Done",
      "export": "Export",
      "remove": "Remove",
      "save": "Save"
    },
    "activity-report": {
      "activity-period": "Activity Period",
      "errors": "No activity found during ",
      "header": "Create activity report",
      "instructions": "Activity reports are currently available only for the last 12 months.",
      "message": "Report includes 1st of month through day prior to report request date.",
      "month": "Month",
      "year": "Year"
    },
    "admin": {
      "currently-viewing": "Currently viewing..."
    },
    "admin-header": {
      "viewing": "Currently viewing..."
    },
    "app": {
      "email": "onemobility@toyotaconnected.com",
      "enterprise": "Enterprise",
      "name": "Marketplace"
    },
    "cognito": {
      "errors": {
        "CodeMismatchException": "Invalid verification code.",
        "NoSubscriberIdException": "There was a problem with your subscriber id. Please contact support.",
        "NotAuthorizedException": "Incorrect email and/or password.",
        "UserNotAuthorizedException": "You have to log in for the first time before reset your password",
        "UserNotFoundException": "User does not exist"
      }
    },
    "collision-detection": {
      "body": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua",
      "title": "Collision Detection"
    },
    "details-table": {
      "nav": {
        "active": "Active ({count})",
        "errors": "Errors ({count})",
        "pending": "Pending ({count})",
        "vins": "Vins"
      }
    },
    "dictionary": {
      "deactivate": "unenroll",
      "edit": "Edit",
      "file": "file",
      "files": "Files",
      "new": "New",
      "region": "Region",
      "selected": "selected",
      "service": "service",
      "vehicle": "vehicle",
      "vin": "VIN",
      "vins": "VINs"
    },
    "driver-behavior": {
      "body": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua",
      "title": "Driver Behavior"
    },
    "driver-score": {
      "body": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua",
      "title": "Driver Score"
    },
    "forms": {
      "add-more": "Add another file",
      "browse": "Browse files",
      "create-password": {
        "title": "Create new password",
        "welcome": "Welcome"
      },
      "csv-files-here": "your .csv files here.",
      "drag-and-drop": "Drag & Drop",
      "drop-here": "Drop your files to upload",
      "forgot-password": {
        "buttons": {
          "confirm-new-password": "Confirm New Password",
          "enter-code": "Enter Code",
          "send-code": "Send Code"
        },
        "confirmation": {
          "message": "Please check your email for a 6 digit verification code. Didn’t receive an email?",
          "title": "Verification code sent!"
        },
        "instructions": "Enter the email address associated with your account, and we’ll send you a verification code.",
        "resend": "Resend",
        "resend-code": "Resend Code",
        "title": "Reset password"
      },
      "labels": {
        "confirm-password": "Confirm Password",
        "email": "Email",
        "mfa": "6 DIGIT CODE",
        "new-password": "New Password",
        "password": "Password",
        "verification-code": "Verification Code"
      },
      "login": {
        "buttons": {
          "forgot-password": "Forgot Password",
          "submit": "Login"
        },
        "password": {
          "hide": "Hide",
          "show": "Show"
        },
        "placeholders": {
          "email": "Enter your email address",
          "mfaCode": "Enter your MFA Code",
          "password": "Enter your password"
        },
        "title": "Log in"
      },
      "mfa": {
        "add-app": "Instructions to Setup MFA",
        "add-by-qr": "2. Add a new account to the authenticator app by scanning the QR code below",
        "add-by-secret": "3. To add an account manually type the text code below into the app ",
        "add-text-code": "How to add a text code?",
        "app-info": "Authenticator apps generate random codes that you can use to sign in. They do not have access to your password or account information.",
        "auth-app": "1. Open your Authenticator App",
        "buttons": {
          "reset-device": "Reset MFA Device",
          "submit": "Submit",
          "verify": "Verify"
        },
        "create-request": "Please submit a ticket to the Mobility Operations team to set up a new MFA device.",
        "download-info": "To Download:",
        "download-step1": "1. Go to your phone's App store.",
        "download-step2": "2. Search for Authenticator App.",
        "download-step3": "3. Download an app (Eg. Google Authenticator, Microsoft Authenticator)",
        "enter-code": "4. Enter the 6-digit code generated by the authenticator app below",
        "heading": "Multi-Factor Authentication",
        "mobility-link": "https://toyotaconnected.atlassian.net/servicedesk/customer/portal/4/group/100/create/494",
        "need-app": "Need an App?",
        "setup-info": "Rather than having a One Time Password (OTP) texted to you every time you Sign-In, you will use an Authenticator app on your phone to generate an OTP. You will enter the generated OTP at Sign-In the same way as with texted OTP. ",
        "submit-link": "Submit Ticket",
        "text-code": "How to add a text code?",
        "text-code1": "1. Open your Authenticator App and select 'Manually add account' from the menu.",
        "text-code2": "2. In 'Enter account name' type your full email address.",
        "text-code3": "3. In 'Enter your key' type the manual code",
        "text-code4": "4. Set the key to 'Timebased'",
        "text-code5": "5. Tap Add.",
        "title": "Setup MFA",
        "verify-info": "For added security, please enter the One Time Password (OTP) generated by your Authenticator App",
        "verify-title": "Two-Step Verification"
      },
      "reset-password": {
        "confirmation": {
          "title": "Password Updated!"
        },
        "errors": {
          "confirm-password": "Password doesn't match",
          "password-strength": "Password doesn't meet the minimum requirements.",
          "verification-code": "Verification code must be 6 digits."
        },
        "instructions": "Password must be a minimum of 8 characters with 1 uppercase letter and 1 special character.",
        "placeholders": {
          "confirm-new-password": "Confirm your new password",
          "new-password": "Enter your new password",
          "verification-code": "Enter your 6 digit verfication code"
        }
      }
    },
    "learn-more": {
      "title": "Looking to add more services?"
    },
    "navigation": {
      "activity-report": "Activity Report",
      "back": "Back",
      "close": "Close",
      "enroll": "Enroll/Unenroll",
      "enroll-vehicles": "Enroll New Vehicles",
      "enrollment-history": "Enrollment History",
      "enrollment-report": "Enrollment Report",
      "logout": "Logout",
      "next": "Next",
      "overview": "Overview",
      "requests": "Enrollment Requests",
      "services": "Services",
      "subscribers": "Subscribers",
      "vehicles": "Vehicles"
    },
    "order": {
      "buttons": {
        "order-id": "Order ID",
        "report-issue": "Report Issue",
        "unenroll": "Unenroll {count} {count, plural, <=1 {# Vehicle} other {# Vehicles}}",
        "unenroll.vehicle": "vehicle",
        "unenroll.vehicles": "vehicles"
      },
      "copy": {
        "error": "Could not copy OrderID to clipboard",
        "success": "Successfully copied OrderID to clipboard"
      },
      "flash": {
        "error": "There was a problem removing your vehicle, please try again.",
        "success": "Vehicle removed from order"
      },
      "header": {
        "service": "Service ({count})",
        "status": "Status",
        "vehicles": "{count, plural, =1 {# Vehicle} other {# Vehicles}}",
        "vins": "Vins"
      },
      "order-type": "Order Type",
      "remove-all": "Remove All",
      "requested": "Order Requested on {date} by {createdBy}",
      "source": "Source"
    },
    "orders": {
      "errors": {
        "no-results": "No results found"
      },
      "header": {
        "order-type": "Order Type",
        "requested": "Requested",
        "services": "Services",
        "source": "Source",
        "status": "Status",
        "title": "Orders",
        "vehicles": "Vehicles"
      },
      "list-state": {
        "empty": "There were no orders matching your filters.",
        "error": "Something went wrong."
      },
      "statuses": {
        "completed": "Completed",
        "in-progress": "In Progress"
      },
      "updated-on": "Updated on"
    },
    "overview": {
      "all-orders": "All Orders",
      "first-time": {
        "enroll": "Enroll vehicles to start managing your fleet",
        "get-started": "Get Started",
        "more-info": "Here's what you'll need to do before you get started"
      },
      "hello": "Hello,",
      "recent-orders": {
        "list-state": {
          "empty": "There are no recent orders.",
          "error": "Something went wrong."
        },
        "title": "Recent Orders"
      },
      "test-user": "Favorite Administrator!",
      "welcome": "Welcome",
      "welcome-to": "Welcome to"
    },
    "placeholder": "[{text}]",
    "prototype": {
      "coming-soon": "Coming Soon",
      "username": "A. Good Testname"
    },
    "services": {
      "title": "Services",
      "unsubscribed": "Unsubscribed"
    },
    "sign-in": {
      "forgot-password": "Forgot your login information?",
      "title": "Login to Toyota Connected"
    },
    "subscribers": {
      "header": {
        "createdAt": "Created",
        "members": "Members",
        "services": "Services",
        "subscriber": "Subscriber",
        "vehicles": "Vehicles"
      },
      "loading-message": "Fetching subscribers...",
      "subscriber-details": {
        "button": {
          "visit-account": "Visit Account"
        },
        "contact": "Contact",
        "createdAt": "Created",
        "member-count": "Members ({count})",
        "primary-contact": "Contact",
        "vehicles": "Vehicles",
        "vins": "Vins"
      },
      "title": "Subscribers",
      "visit-account": "Visit Account"
    },
    "support": {
      "contact-sales": "Contact Sales",
      "issue": "Report an issue"
    },
    "table-filters": {
      "date": "Date",
      "date-range-end": "End",
      "date-range-start": "Start"
    },
    "telemetry": {
      "body": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua",
      "title": "Telemetry"
    },
    "vehicle": {
      "buttons": {
        "deactivate": "Unenroll Vehicle",
        "report-issue": "Report Issue"
      },
      "header": {
        "services": "Services ({count})",
        "status": "Status"
      },
      "updated": "Vehicle updated on {date} by {name}"
    },
    "vehicles": {
      "deactivate": {
        "cancel": "Cancel",
        "confirmation": {
          "body": "All active services will end for the selected vehicles.",
          "title": "Unenroll {count, plural, =1 {(#) Vehicle} other {(#) Vehicles}}?"
        },
        "error": "Unable to Unenroll Vehicle",
        "idle": "Wait",
        "running": "Unenrolling vehicles",
        "success": "Vehicle Unenrolled Successfully"
      },
      "errors": {
        "error-unspecified": "Error Unspecified",
        "no-results": "No results found",
        "owner-validation": {
          "body": "The following vehicles failed enrollment, however our support team is actively investigating the issue which can take up to 1-2 weeks. There’s no action required from you, however we appreciate your patience and will reach out if needed. If you have any questions or concerns, please contact your Toyota business representative.",
          "title": "Something went wrong, but we’re on it."
        }
      },
      "flash": {
        "deactivate": {
          "bulk-error": "There was a problem unenrolling your {count, plural, <=1 {# vehicle} other {# vehicles}}",
          "bulk-success": "Unenrolled {count, plural, <=1 {# vehicle} other {# vehicles}} successfully",
          "error": "Unable to Unenroll Vehicle",
          "idle": "Wait",
          "running": "Unenrolling vehicles",
          "success": "Vehicle Unenrolled Successfully"
        }
      },
      "header": {
        "actions": "Actions",
        "enrolled": "Enrolled",
        "error": "Errors",
        "filter": "Filter:",
        "last-updated": "Last Updated",
        "services": "Services",
        "tags": "Tags",
        "title": "Vehicles",
        "vin": "VIN"
      },
      "list-state": {
        "empty": "There were no VINs matching your filters.",
        "error": "Something went wrong."
      },
      "num-vehicles": "{count} vehicles",
      "response-statuses": {
        "completed": "Active",
        "error": "Inactive",
        "pending": "Pending",
        "pending-provisioning": "Pending Provisioning",
        "unenrolled": "Unknown"
      },
      "service-statuses": {
        "completed": "(enrolled)",
        "error": "(error)",
        "pending": "(pending)",
        "unenrolled": "(inactive)"
      },
      "statuses": {
        "completed": "Enrolled",
        "error": "Error",
        "pending": "Pending",
        "pending-provisioning": "Pending Provisioning",
        "unenrolled": "Inactive"
      },
      "updated-on": "Updated on"
    },
    "wizard": {
      "buttons": {
        "continue": "Continue"
      },
      "confirmation": {
        "disenroll": "Unenrollment",
        "disenroll-title": "Confirm your unenrollment request",
        "enroll": "Enrollment",
        "enroll-title": "Confirm your enrollment request",
        "headers": {
          "files": "Files ({count})",
          "order-type": "Action Type",
          "services": "Services ({count})",
          "vehicles": "Vehicles ({count})"
        },
        "submit": "Submit request",
        "submitting": "Submitting..."
      },
      "exit": {
        "buttons": {
          "back": "Back",
          "exit": "Exit Now"
        },
        "message": "All actions will be lost during this enroll/unenroll session.",
        "title": "Continue to exit?"
      },
      "file-rules": {
        "duplicates": "Check for duplicate VINs",
        "format": "Save your file as a .CSV",
        "only-vins": "Submit only a list of VINs",
        "sample": "Sample file",
        "title": "File format guide",
        "vin-list": "One VIN per line"
      },
      "flash": {
        "disenroll": {
          "error": "There was a problem submitting your unenrollment order, please try again.",
          "success": "Unenrollment Order Submitted!"
        },
        "enroll": {
          "error": "There was a problem submitting your enrollment order, please try again.",
          "success": "Enrollment Order Submitted!"
        }
      },
      "order-type": {
        "disenroll": "Unenroll",
        "disenroll-desc": "Select this option if you wish to unenroll new vehicles or services.",
        "enroll": "Enroll",
        "enroll-desc": "Select this option if you wish to enroll new vehicles or services.",
        "title": "Select an order type."
      },
      "services-title": "Select your services.",
      "upload": {
        "buttons": {
          "delete-file": "Delete File",
          "ignore-errors": "Ignore {num} errors"
        },
        "errors": {
          "ambigious-characters": "VIN must not contain ambiguous characters (I, O, or Q)",
          "duplicate": "This VIN is duplicated on {count, plural, one {line} other {lines}}: {lineNumbers}",
          "errors-found": "{count} Errors",
          "length": "VIN must be 17 characters, was {length}",
          "special-characters": "VIN must not contain special characters",
          "submit": "Submit Enrollment"
        },
        "table-headers": {
          "file-name": "FILE",
          "file-size": "SIZE",
          "line": "Line",
          "message": "Message",
          "vins": "VINS"
        },
        "title": "Upload VINs"
      }
    }
  };
  _exports.default = _default;
});