define("data-marketplace-ui/utils/validators", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    gte: function gte(length, field) {
      return function (val, intl) {
        if (val && val.length >= length) {
          return null;
        }

        return intl.t('@loom.ember-auth.errors.validation-length', {
          field: field,
          length: length
        });
      };
    },
    email: function email(val, intl) {
      var expr = /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/i;

      if (expr.test(val)) {
        return null;
      }

      return intl.t('@loom.ember-auth.errors.validation-email');
    },
    presence: function presence(field) {
      return function (val, intl) {
        if (typeof val === 'boolean') {
          return null;
        } else if (val) {
          return null;
        }

        return intl.t('@loom.ember-auth.errors.validation-presence', {
          field: field
        });
      };
    },
    // Password must be a minimum of 8 characters with 1 uppercase letter and 1 special character.
    passwordStrength: function passwordStrength(val, intl) {
      var expr = /^(?=.{8,})(?=.*[A-Z])(?=.*[!@#$%^&])/;

      if (expr.test(val)) {
        return null;
      }

      return intl.t('forms.reset-password.instructions');
    },
    emailNotAuthorized: function emailNotAuthorized(errorCode, intl) {
      if (errorCode === 'NotAuthorizedException') {
        return intl.t('cognito.errors.UserNotAuthorizedException');
      }

      return null;
    }
  };
  _exports.default = _default;
});