define("data-marketplace-ui/components/form-actions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @class FormActions
   * @description A wrapper for your form's actions (i.e: Submit, Reset, etc.)
   *
   * The 'flex-direction' is set to 'row-reverse' by default. This requires you to pass in your primary action first,
   * if you want it to appear on the right, but ensures that hitting Enter in your form performs the primary action as intended.
    Example:
    ```handlebars
    <FormActions>
     <ButtonBasic @text="Submit" @type="Submit" />
     <ButtonBasic @text="Secondary Action" />
    </FormActions>
    ````
    Would render the buttons in the following order:
  
    [Secondary Action] [Submit]
   */
  var _default = Ember.Component.extend({
    tagName: ''
  });

  _exports.default = _default;
});