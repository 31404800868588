define("data-marketplace-ui/controllers/vehicle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    store: Ember.inject.service(),
    vehicle: Ember.computed.alias('model'),
    vehicleStatus: null,
    services: Ember.computed.alias('vehicle.subscriptions'),
    actions: {
      closeSidebar: function closeSidebar() {
        this.transitionToRoute('vehicles');
      }
    }
  });

  _exports.default = _default;
});