define("data-marketplace-ui/adapters/enrollment", ["exports", "data-marketplace-ui/adapters/application", "fetch"], function (_exports, _application, _fetch) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    namespace: 'v2/vehicles/enrollments/bulk',
    currentUser: Ember.inject.service(),
    createRecord: function createRecord(store, type, snapshot) {
      var host = this.host,
          apikey = this.apikey,
          namespace = this.namespace,
          token = this.token;
      return (0, _fetch.default)("".concat(host, "/mgmt/").concat(namespace), {
        method: 'POST',
        headers: {
          'x-id-token': token,
          'Content-Type': 'application/json',
          source: 'FILE',
          'file-name': 'test.csv',
          username: this.currentUser.username,
          apikey: apikey
        },
        body: JSON.stringify(snapshot)
      }).then(function (res) {
        return res.json();
      });
    }
  });

  _exports.default = _default;
});