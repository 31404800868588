define("data-marketplace-ui/routes/vehicle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    store: Ember.inject.service(),
    model: function model(_params, transition) {
      var vehicleId = transition.to.queryParams.vehicleId;

      if (vehicleId) {
        return this.store.findRecord('vehicle', vehicleId);
      }

      return this.transitionTo('vehicles');
    },
    setupController: function setupController(controller, vehicle) {
      this._super(controller, vehicle);

      var _this$controllerFor = this.controllerFor('vehicles'),
          status = _this$controllerFor.status;

      Ember.set(controller, 'vehicleStatus', status);
    }
  });

  _exports.default = _default;
});