define("data-marketplace-ui/mirage/scenarios/default", ["exports", "data-marketplace-ui/mirage/helpers/create-services"], function (_exports, _createServices) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  // Map the correct ids to the service for the sake of our icons.
  function _default(server) {
    /*
      Seed your development database using your factories.
      This data will not be loaded in your tests.
    */
    // server.createList('post', 10);
    (0, _createServices.default)(server);
    server.createList('vehicle', 60);
    server.createList('order', 50);
    server.create('vehicles-count', {
      completed: 36,
      pending: 20,
      error: 4
    });
  }
});