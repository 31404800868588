define("data-marketplace-ui/templates/components/svg/svg-close", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "oO26LSmZ",
    "block": "{\"symbols\":[],\"statements\":[[2,\"  \"],[10,\"path\"],[14,\"d\",\"M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z\"],[12],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "data-marketplace-ui/templates/components/svg/svg-close.hbs"
    }
  });

  _exports.default = _default;
});