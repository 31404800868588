define("data-marketplace-ui/components/svg-search", ["exports", "@loom/ember-svgs/components/svg"], function (_exports, _svg) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _svg.default.extend({
    viewBox: '0 0 24 24'
  });

  _exports.default = _default;
});