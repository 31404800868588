define("data-marketplace-ui/routes/subscriber-details", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    store: Ember.inject.service(),
    model: function model(params) {
      return this.store.findRecord('subscriber', params.subscriber_id);
    }
  });

  _exports.default = _default;
});