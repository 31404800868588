define("data-marketplace-ui/services/mage", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @class Mage
   * @description The mage service manages the visibility of the `enrollment-wizard` component so
   * that it can be launched/dismissed from anywhere in the app.
   */
  var _default = Ember.Service.extend({
    metrics: Ember.inject.service(),
    init: function init() {
      this._super.apply(this, arguments);

      this.appear = this.appear.bind(this);
      this.vanish = this.vanish.bind(this);
    },
    isVisible: false,
    appear: function appear() {
      Ember.set(this, 'isVisible', true);
      this.metrics.trackEvent({
        event: 'enrollment-flow::order-type'
      });
    },
    vanish: function vanish() {
      Ember.set(this, 'isVisible', false);
    }
  });

  _exports.default = _default;
});