define("data-marketplace-ui/helpers/format-file-size", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.formatFileSize = formatFileSize;
  _exports.default = void 0;

  function formatFileSize(size) {
    if (size < 1024) {
      return "".concat(size, " B");
    }

    var unit = size < 1024 * 1024 ? 'KB' : 'MB';
    return "".concat(Math.round(size / 1024), " ").concat(unit);
  }

  var _default = Ember.Helper.helper(formatFileSize);

  _exports.default = _default;
});