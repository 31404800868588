define("data-marketplace-ui/mirage/helpers/rand", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.randomInt = randomInt;
  _exports.randomNum = randomNum;
  _exports.coinFlip = coinFlip;
  _exports.rollDie = rollDie;
  _exports.randString = randString;

  function randomInt(min, max) {
    return Math.floor(Math.random() * (max - min)) + min; // The maximum is exclusive and the minimum is inclusive
  }

  function randomNum(min, max) {
    return Math.random() * (max - min) + min; // The maximum is exclusive and the minimum is inclusive
  }

  function coinFlip() {
    return randomInt(0, 2) === 0;
  }

  function rollDie() {
    var sides = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 6;
    return randomInt(0, sides) === 0;
  }

  function randString() {
    return Math.random().toString(36).substring(2);
  }
});