define("data-marketplace-ui/components/date-range-filter", ["exports", "luxon"], function (_exports, _luxon) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['table-filter', 'date-range-filter'],
    intl: Ember.inject.service(),
    init: function init() {
      this._super.apply(this, arguments);

      var today = _luxon.DateTime.local();

      var _start = today.startOf('month');

      var _end = today.endOf('month');

      var monthInDays = _luxon.Interval.fromDateTimes(_start, _end).length('days');

      var center = _start.plus({
        days: Math.floor(monthInDays / 2)
      });

      var start = this.selectedStartDate ? _luxon.DateTime.fromISO(this.selectedStartDate).toJSDate() : null;
      var end = this.selectedEndDate ? _luxon.DateTime.fromISO(this.selectedEndDate).toJSDate() : null;
      var range = {
        start: start,
        end: end
      };
      Ember.setProperties(this, {
        range: range,
        center: center
      });
    },

    /**
     * @private
     */
    startDate: Ember.computed('range.start', {
      get: function get() {
        if (this.range.start) {
          return this.range.start.toLocaleDateString();
        }

        return this.range.start;
      }
    }),

    /**
     * @private
     */
    endDate: Ember.computed('range.end', {
      get: function get() {
        if (this.range.end) {
          return this.range.end.toLocaleDateString();
        }

        return this.range.end;
      }
    }),

    /**
     * @private
     * @description Shows the filter name, or the selected date range, if it exists.
     */
    triggerLabel: Ember.computed('startDate', 'endDate', {
      get: function get() {
        if (this.startDate && this.endDate) {
          return "".concat(this.startDate, " - ").concat(this.endDate);
        }

        return this.intl.t('table-filters.date');
      }
    }),

    /**
     * @private
     * @description An object that contains the start and end date of the selected range. Used as the
     * `selected` arg in `PowerCalendarRange`
     */
    range: null,

    /**
     * @private
     * @description Determines whether or not both dates in the range have been selected. Currently used
     * to enable/disable the "Apply" button
     */
    rangeIsSelected: Ember.computed('range', {
      get: function get() {
        var _this$range = this.range,
            start = _this$range.start,
            end = _this$range.end;
        return Boolean(start) && Boolean(end);
      }
    }),

    /**
     * @public
     * @description Action to perform after the date has been updated.
     */
    onUpdateDateRange: null,

    /**
     * @private
     */
    clearDateRange: function clearDateRange() {
      var updatedRange = {
        start: null,
        end: null
      };
      Ember.set(this, 'range', updatedRange);
      this.onUpdateDateRange(updatedRange);
    }
  });

  _exports.default = _default;
});