define("data-marketplace-ui/components/service-indicators", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var statusPriority = [{
    name: 'Unknown',
    priority: 4
  }, {
    name: 'Active',
    priority: 3
  }, {
    name: 'Pending',
    priority: 2
  }, {
    name: 'Inactive',
    priority: 1
  }];

  var _default = Ember.Component.extend({
    classNames: ['service-indicators'],

    /**
     * @private
     * @description Sorts two values.
     */
    sort: function sort(a, b) {
      return a > b ? 1 : a < b ? -1 : 0;
    },

    /**
     * @description Returns an array of services sorted alphabetically by name and prioritized by status (if applicable)
     */
    sortServices: Ember.computed('services', {
      get: function get() {
        var _this = this;

        return this.services.sort(function (a, b) {
          var priorityA = statusPriority.find(function (x) {
            return x.name === a.status;
          });
          var priorityB = statusPriority.find(function (x) {
            return x.name === b.status;
          });

          if (priorityB && priorityA) {
            return priorityA === priorityB ? _this.sort(a.product, b.product) // sort alphabetically by product name
            : _this.sort(priorityA.priority, priorityB.priority); // sort by service status priority
          }

          return _this.sort(a.label, b.label); // sort alphabetically by product name
        });
      }
    })
  });

  _exports.default = _default;
});