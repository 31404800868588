define("data-marketplace-ui/templates/components/form-actions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "XH8cS3L8",
    "block": "{\"symbols\":[\"&attrs\",\"&default\"],\"statements\":[[11,\"div\"],[24,0,\"ui-form__actions flex flex-row\"],[17,1],[12],[2,\"\\n  \"],[18,2,null],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "data-marketplace-ui/templates/components/form-actions.hbs"
    }
  });

  _exports.default = _default;
});