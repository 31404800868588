define("data-marketplace-ui/components/form-item", ["exports", "@loom/ember-forms/components/form-item"], function (_exports, _formItem) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _formItem.default;
    }
  });
});