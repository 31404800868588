define("data-marketplace-ui/templates/components/svg/svg-arrow-right", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "E6nCRTku",
    "block": "{\"symbols\":[],\"statements\":[[10,\"polygon\"],[14,\"transform\",\"translate(7, 5)\"],[14,\"points\",\"2 0 .59 1.41 5.17 6 .59 10.59 2 12 8 6\"],[12],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "data-marketplace-ui/templates/components/svg/svg-arrow-right.hbs"
    }
  });

  _exports.default = _default;
});