define("data-marketplace-ui/mirage/serializers/application", ["exports", "ember-cli-mirage"], function (_exports, _emberCliMirage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // mirage/serializers/application.js
  var _default = _emberCliMirage.RestSerializer.extend({});

  _exports.default = _default;
});