define("data-marketplace-ui/components/drop-target", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['drop-target', 'text--center'],
    classNameBindings: ['isDragging'],
    dragOver: function dragOver(event) {
      event.preventDefault();
      Ember.set(this, 'isDragging', true);
    },
    drop: function drop(event) {
      event.preventDefault();
      var files = [];

      if (event.dataTransfer.items) {
        // Use DataTransferItemList interface to access the file(s)
        for (var i = 0; i < event.dataTransfer.items.length; i++) {
          // If dropped items aren't files, reject them
          if (event.dataTransfer.items[i].kind === 'file') {
            var file = event.dataTransfer.items[i].getAsFile();
            files.push(file);
          }
        }
      } else {
        // Use DataTransfer interface to access the file(s)
        for (var _i = 0; _i < event.dataTransfer.files.length; _i++) {
          files.push(event.dataTransfer.files[_i]);
        }
      }

      Ember.set(this, 'isDragging', false);
      this.onDrop(files);
    }
  });

  _exports.default = _default;
});