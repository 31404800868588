define("data-marketplace-ui/templates/components/step-progress", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "aVnKbidU",
    "block": "{\"symbols\":[\"step\"],\"statements\":[[6,[37,6],[[30,[36,5],[[30,[36,5],[[30,[36,4],[1,[35,3],true],null]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"li\"],[15,0,[31,[\"step-progress__step \",[30,[36,2],[[30,[36,1],[[32,1],[35,0]],null],\"step-progress__step--active\"],null]]]],[12],[13],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"currentStep\",\"eq\",\"if\",\"total\",\"range\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "data-marketplace-ui/templates/components/step-progress.hbs"
    }
  });

  _exports.default = _default;
});