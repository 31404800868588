define("data-marketplace-ui/mirage/serializers/order", ["exports", "data-marketplace-ui/mirage/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    keyForAttribute: function keyForAttribute(attr) {
      if (attr === 'id') {
        return 'orderId';
      }

      return attr;
    }
  });

  _exports.default = _default;
});