define("data-marketplace-ui/templates/components/service-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "i40dPI7w",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,3],[[32,0,[\"iconName\"]]],[[\"width\",\"height\"],[\"40px\",\"40px\"]]]],[2,\"\\n\\n\"],[6,[37,4],[[35,0,[\"product\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[1,[30,[36,3],[\"status\"],[[\"class\"],[[30,[36,2],[\"status-indicator \",[30,[36,1],[[35,0,[\"status\"]]],null]],null]]]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"service\",\"lowercase\",\"concat\",\"svg-jar\",\"if\"]}",
    "meta": {
      "moduleName": "data-marketplace-ui/templates/components/service-icon.hbs"
    }
  });

  _exports.default = _default;
});