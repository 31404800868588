define("data-marketplace-ui/components/header-link", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    class: 'ember-tableish__header-link',
    activeClass: 'ember-tableish__header-link--active',
    descClass: 'ember-tableish__header-link--desc',
    _class: Ember.computed('sort', 'desc', {
      get: function get() {
        if (this.sort === this.desc) {
          return "".concat(this.class, " ").concat(this.descClass);
        }

        return this.class;
      }
    }),
    _sortQp: Ember.computed('sort', 'asc', 'desc', 'searchTerm', {
      get: function get() {
        var asc = this.asc;

        if (this.sort === asc) {
          return this.desc;
        }

        return asc;
      }
    }),
    _isCurrent: Ember.computed('sort', 'asc', 'desc', {
      get: function get() {
        var sort = this.sort;
        return sort === this.asc || sort === this.desc;
      }
    })
  });

  _exports.default = _default;
});