define("data-marketplace-ui/templates/services", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Zrf923xu",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"services-page\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"services-page__header\"],[12],[2,\"\\n    \"],[8,\"title-text\",[],[[\"@text\",\"@weight\",\"@size\"],[[30,[36,0],[\"services.title\"],null],\"semibold\",\"title-4\"]],null],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"services-page__services-container\"],[12],[2,\"\\n    \"],[8,\"service\",[],[[\"@title\",\"@body\",\"@vehicleNumber\"],[[30,[36,0],[\"telemetry.title\"],null],[30,[36,0],[\"telemetry.body\"],null],\"750\"]],null],[2,\"\\n    \"],[8,\"service\",[],[[\"@title\",\"@body\",\"@vehicleNumber\"],[[30,[36,0],[\"driver-behavior.title\"],null],[30,[36,0],[\"driver-behavior.body\"],null],\"750\"]],null],[2,\"\\n    \"],[8,\"service\",[],[[\"@title\",\"@body\",\"@vehicleNumber\"],[[30,[36,0],[\"collision-detection.title\"],null],[30,[36,0],[\"collision-detection.body\"],null],\"750\"]],null],[2,\"\\n    \"],[8,\"service\",[],[[\"@title\",\"@body\"],[[30,[36,0],[\"driver-score.title\"],null],[30,[36,0],[\"collision-detection.body\"],null]]],null],[2,\"\\n\\n    \"],[1,[34,1]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\",\"learn-more\"]}",
    "meta": {
      "moduleName": "data-marketplace-ui/templates/services.hbs"
    }
  });

  _exports.default = _default;
});