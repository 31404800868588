define("data-marketplace-ui/serializers/subscriber", ["exports", "data-marketplace-ui/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    attrs: {
      primaryKey: 'subscriberId',
      name: 'subscriberName'
    },
    normalizeFindRecordResponse: function normalizeFindRecordResponse(store, primaryModelClass, payload, id, requestType) {
      var normalizedPayload = {
        data: {
          id: payload.subscriberId,
          type: 'subscriber',
          attributes: payload
        }
      };
      return this._super(store, primaryModelClass, normalizedPayload, id, requestType);
    },
    normalizeFindAllResponse: function normalizeFindAllResponse(store, primaryModelClass, payload, id, requestType) {
      var normalizedPayload = {
        data: payload.subscribers.map(function (subscriber) {
          return {
            id: subscriber.subscriberId,
            type: 'subscriber',
            attributes: subscriber
          };
        })
      };
      return this._super(store, primaryModelClass, normalizedPayload, id, requestType);
    }
  });

  _exports.default = _default;
});