define("data-marketplace-ui/mirage/factories/order", ["exports", "ember-cli-mirage", "lodash", "faker"], function (_exports, _emberCliMirage, _lodash, _faker) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCliMirage.Factory.extend({
    enrolled: (0, _emberCliMirage.trait)({
      status: 'enrolled'
    }),
    requestedAt: function requestedAt() {
      return _faker.default.date.recent(1).toISOString();
    },
    requestedBy: function requestedBy() {
      return "".concat(_faker.default.name.firstName(), " ").concat(_faker.default.name.lastName());
    },
    source: function source() {
      return _faker.default.random.arrayElement(['API', 'FILE', 'AUTO']);
    },
    type: function type() {
      return _faker.default.random.arrayElement(['Enrollment', 'Unenrollment']);
    },
    status: function status() {
      return _faker.default.random.arrayElement(['pending', 'completed']);
    },
    completed: function completed() {
      return _faker.default.random.number({
        min: 0,
        max: 2000
      });
    },
    pending: function pending() {
      return _faker.default.random.number({
        min: 0,
        max: 2000
      });
    },
    error: function error() {
      return _faker.default.random.number({
        min: 0,
        max: 2000
      });
    },
    vehicles: function vehicles() {
      return _faker.default.random.number();
    },
    products: function products() {
      var products = ['Telemetry', 'Driver Score', 'Event Notification', 'Non Automatic Collision Notification'];
      return (0, _lodash.sampleSize)(products, _faker.default.random.number({
        min: 1,
        max: 4
      }));
    }
  });

  _exports.default = _default;
});