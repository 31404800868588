define("data-marketplace-ui/models/subscription", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo;
  /**
   * TODO: map backend attr names
   * -----------------------------
   * type
   * name
   * label
   * description
   * --- may not need the things below this line for the UI --
   * default_config
   * last_modified_time -> lastModifiedAt
   * created_time -> createdAt
   * created_by -> createdBy
   * modified_by -> modifiedBy
   */

  var _default = Model.extend({
    product: attr('string'),
    productId: attr('string'),
    status: attr('string'),
    description: attr('string'),
    createdTimestamp: attr('date'),
    modifiedTimestamp: attr('date'),
    createdBy: attr('string'),
    modifiedBy: attr('string'),
    vehicle: belongsTo('vehicle', {
      async: false
    })
  });

  _exports.default = _default;
});