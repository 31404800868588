define("data-marketplace-ui/templates/components/svg-disclose", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "MFvziJqw",
    "block": "{\"symbols\":[],\"statements\":[[10,\"path\"],[14,\"d\",\"M3.3602591,8 L20.6397409,8 C20.9158833,8 21.1397409,8.22385763 21.1397409,8.5 C21.1397409,8.64668432 21.0753297,8.78596979 20.9635651,8.88096966 L12.3238242,16.2247494 C12.1371099,16.3834565 11.8628901,16.3834565 11.6761758,16.2247494 L3.03643489,8.88096966 C2.82603116,8.70212649 2.80044627,8.38657952 2.97928944,8.17617579 C3.07428931,8.06441124 3.21357478,8 3.3602591,8 Z\"],[14,1,\"path-1\"],[12],[13]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "data-marketplace-ui/templates/components/svg-disclose.hbs"
    }
  });

  _exports.default = _default;
});