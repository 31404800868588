define("data-marketplace-ui/templates/components/service-card", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "LJ+quaCL",
    "block": "{\"symbols\":[],\"statements\":[[8,\"service-icon\",[],[[\"@selected\",\"@service\"],[[34,0],[34,1]]],null],[2,\"\\n\\n\\n\"],[8,\"title-text\",[],[[\"@text\",\"@class\"],[[34,1,[\"label\"]],\"text--subtitle-1\"]],null],[2,\"\\n\"],[8,\"body-text\",[[24,0,\"mt--small text--body-2\"]],[[\"@text\"],[[34,1,[\"description\"]]]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"selected\",\"service\"]}",
    "meta": {
      "moduleName": "data-marketplace-ui/templates/components/service-card.hbs"
    }
  });

  _exports.default = _default;
});