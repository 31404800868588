define("data-marketplace-ui/components/placeholder-block", ["exports", "@loom/ember-content-placeholders/components/placeholder-block"], function (_exports, _placeholderBlock) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _placeholderBlock.default;
    }
  });
});