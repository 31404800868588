define("data-marketplace-ui/translations/ja", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "@loom": {
      "ember-auth": {
        "actions": {
          "forgot-password": "パスワードをお忘れの方",
          "return-to-login": "ログイン画面に戻る",
          "sign-in": "サインインの方はこちら",
          "sign-up": "新規サインアップの方はこちら"
        },
        "errors": {
          "conflict": "このメールアドレスは既に別の方が利用しています。",
          "failed": "無効なメールアドレスまたはパスワードの組み合わせ",
          "unknown": "要求を完了することは出来ませんでした。後程、再度お試しください"
        },
        "form": {
          "buttons": {
            "next": "次",
            "reset-password": "パスワードをリセットする",
            "sign-in": "サインイン",
            "sign-up": "サインアップ"
          },
          "labels": {
            "confirm-password": "パスワードを確認する",
            "email": "メールアドレス",
            "first-name": "名前",
            "last-name": "姓名",
            "name": "名前",
            "new-password": "新しいパスワード",
            "password": "パスワード",
            "phone": "電話番号",
            "role": "役割",
            "terms-and-conditions": {
              "1": "同意します",
              "2": "規約同意"
            }
          }
        },
        "instructions": {
          "forgot-password": {
            "check-email": "パスワードをリセットするやり方に関する説明は<strong>{email}</strong>を確認して下さい",
            "email-sent": "送信完了"
          },
          "reset-password": {
            "redirect-to-sign-in": "サインインして新しいパスワードをご利用いただけます"
          }
        }
      },
      "ember-forms": {
        "errors": {
          "accepted": "{description} は容認されなければいけません",
          "after": "{description}は{after}の後でなければいけません",
          "before": "{description} は {before}の前でなければいけません",
          "blank": "{description} は空白ではいけません",
          "collection": "{description} は集合体でなければいけません",
          "confirmation": "{description} は合っていません {on}",
          "date": "{description}は有効な日付でなければいけません",
          "description": "この項目",
          "email": "{description}は有効なメールアドレスでなければいけません",
          "empty": "{description} は空白ではいけません",
          "equalTo": "{description}は {is}と等しくなければいけません",
          "even": "{description}は偶数でなければいけません",
          "exclusion": "{description}は予約されています",
          "greaterThan": "{description} は {gt}より大きくなければいけません",
          "greaterThanOrEqualTo": "{description}は {gte}以上でなければいけません",
          "inclusion": "{description}はリストの中に含まれていません",
          "invalid": "{description} は無効です",
          "lessThan": "{description} は{lt}より小さくなければいけません",
          "lessThanOrEqualTo": "{description}は {lte}以下でなければいけません",
          "notANumber": "{description}は１つの数字でなければいけません",
          "notAnInteger": "{description} は整数でなければいけません",
          "odd": "{description}は奇数でなければいけません",
          "otherThan": "{description} は {value}以外でなければいけません",
          "phone": "{description}は有効な電話番号でなければいけません",
          "positive": "{description}は正でなければいけません",
          "present": "{description} は空白でなければいけません",
          "singular": "{description} は集合体ではいけません",
          "tooLong": "{description} は長過ぎです (最大で{max}文字です )",
          "tooShort": "{description} は短過ぎです (最小で{max}文字です )",
          "url": "{description}は有効なURLでなければいけません",
          "wrongDateFormat": "{description} は {format}のフォーマットでなければいけません",
          "wrongLength": "{description}は不適切な長さです ({is} 文字でなければいけません)"
        }
      }
    }
  };
  _exports.default = _default;
});