define("data-marketplace-ui/mirage/factories/vehicles-count", ["exports", "ember-cli-mirage", "faker"], function (_exports, _emberCliMirage, _faker) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCliMirage.Factory.extend({
    completed: function completed() {
      return _faker.default.random.number({
        min: 0,
        max: 2000
      });
    },
    error: function error() {
      return _faker.default.random.number({
        min: 0,
        max: 2000
      });
    },
    pending: function pending() {
      return _faker.default.random.number({
        min: 0,
        max: 2000
      });
    }
  });

  _exports.default = _default;
});