define("data-marketplace-ui/templates/components/table-body-wrapper", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "nYSylZTE",
    "block": "{\"symbols\":[\"cp\",\"@emptyText\",\"@errorText\",\"&default\",\"@items\",\"@isLoading\"],\"statements\":[[6,[37,1],[[32,6]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[8,\"content-placeholder\",[],[[\"@pageBgShade\"],[\"light\"]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,4],[[30,[36,3],[[30,[36,3],[[30,[36,2],[0,10],null]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[8,[32,1,[\"placeholderBlock\"]],[[24,0,\"mb--x-small\"]],[[],[]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,1],[[32,5,[\"length\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[18,4,null],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[8,\"title-text\",[],[[\"@text\",\"@size\",\"@palette\",\"@class\"],[[30,[36,1],[[35,0],[32,3],[32,2]],null],\"title-3\",\"black\",\"m--x-large text--center\"]],null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"isError\",\"if\",\"range\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "data-marketplace-ui/templates/components/table-body-wrapper.hbs"
    }
  });

  _exports.default = _default;
});