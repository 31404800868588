define("data-marketplace-ui/templates/application", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "w2+t2RCK",
    "block": "{\"symbols\":[\"flash\",\"component\",\"message\"],\"statements\":[[1,[30,[36,7],[[30,[36,6],null,null]],null]],[2,\"\\n\\n\"],[6,[37,1],[[35,3,[\"queue\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"flash-messages flash-messages--dark\"],[12],[2,\"\\n\"],[6,[37,5],[[30,[36,4],[[30,[36,4],[[35,3,[\"queue\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[6,[37,2],null,[[\"flash\"],[[32,1]]],[[\"default\"],[{\"statements\":[[2,\"        \"],[10,\"div\"],[15,0,[31,[\"flash-messages__indicator flash-messages__indicator--\",[32,3,[\"type\"]]]]],[12],[2,\"\\n          \"],[1,[30,[36,0],[\"check\"],[[\"fill\"],[\"black\"]]]],[2,\"\\n        \"],[13],[2,\"\\n        \"],[10,\"div\"],[14,0,\"flash-messages__text\"],[12],[2,\"\\n          \"],[10,\"div\"],[12],[1,[32,1,[\"message\"]]],[13],[2,\"\\n\"],[6,[37,1],[[32,3,[\"additionalText\"]]],null,[[\"default\"],[{\"statements\":[[2,\"            \"],[10,\"div\"],[12],[1,[32,3,[\"additionalText\"]]],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"        \"],[13],[2,\"\\n        \"],[10,\"div\"],[12],[2,\"\\n          \"],[8,\"button-basic\",[],[[\"@class\",\"@size\"],[\"flash-messages__close\",\"small\"]],[[\"default\"],[{\"statements\":[[2,\"\\n            \"],[1,[30,[36,0],[\"clear\"],null]],[2,\"\\n          \"]],\"parameters\":[]}]]],[2,\"\\n        \"],[13],[2,\"\\n\"]],\"parameters\":[2,3]}]]]],\"parameters\":[1]}]]],[2,\"  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"svg-jar\",\"if\",\"flash-message\",\"flashMessages\",\"-track-array\",\"each\",\"-outlet\",\"component\"]}",
    "meta": {
      "moduleName": "data-marketplace-ui/templates/application.hbs"
    }
  });

  _exports.default = _default;
});