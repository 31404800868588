define("data-marketplace-ui/components/ember-tableish-header", ["exports", "ember-tableish/components/ember-tableish-header"], function (_exports, _emberTableishHeader) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _emberTableishHeader.default;
    }
  });
});