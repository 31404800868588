define("data-marketplace-ui/templates/components/csv-uploader", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "v82mSXPh",
    "block": "{\"symbols\":[],\"statements\":[[10,\"input\"],[14,5,\"display: none;\"],[14,\"accept\",\".csv\"],[14,\"multiple\",\"\"],[14,\"data-test-upload-csv\",\"\"],[15,\"disabled\",[34,0]],[15,\"onchange\",[30,[36,2],[[32,0],[35,1]],[[\"value\"],[\"target\"]]]],[14,4,\"file\"],[12],[13],[2,\"\\n\\n\"],[1,[34,3]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"disabled\",\"mainTask\",\"action\",\"_text\"]}",
    "meta": {
      "moduleName": "data-marketplace-ui/templates/components/csv-uploader.hbs"
    }
  });

  _exports.default = _default;
});