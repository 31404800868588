define("data-marketplace-ui/controllers/authenticated", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // eslint-disable-next-line ember/alias-model-in-controller
  var _default = Ember.Controller.extend({
    session: Ember.inject.service(),
    mage: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    router: Ember.inject.service(),
    isShowingModal: false,
    viewingAsSubscriber: Ember.computed('currentUser.{isAdmin,isImpersonating}', {
      get: function get() {
        return !this.currentUser.isAdmin || this.currentUser.isAdmin && this.currentUser.isImpersonating;
      }
    }),
    actions: {
      backToSubscribers: function backToSubscribers() {
        return this.router.replaceWith('subscribers');
      },
      appear: function appear() {
        this.mage.appear();
      },
      showActivityModal: function showActivityModal() {
        Ember.set(this, 'isShowingModal', true);
      },
      cancelActivityModal: function cancelActivityModal() {
        Ember.set(this, 'isShowingModal', false);
      }
    }
  });

  _exports.default = _default;
});