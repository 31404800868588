define("data-marketplace-ui/templates/components/svg-spinner", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Qi1DLCOv",
    "block": "{\"symbols\":[],\"statements\":[[10,\"circle\"],[14,0,\"path\"],[14,\"fill\",\"none\"],[14,\"stroke-width\",\"8\"],[14,\"stroke-linecap\",\"round\"],[14,\"cx\",\"32\"],[14,\"cy\",\"32\"],[14,\"r\",\"24\"],[12],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "data-marketplace-ui/templates/components/svg-spinner.hbs"
    }
  });

  _exports.default = _default;
});