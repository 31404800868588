define("data-marketplace-ui/models/product-subscription", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo;
  /**
   * TODO: map backend attr names
   * ----------------------------
   * product_id
   * subscriber_id
   * type
   * --- may not need the things below this line for the UI --
   * config
   * last_modified_time
   * created_time
   * created_by
   * modified_by
   */

  var _default = Model.extend({
    product: belongsTo('product'),
    subscriber: belongsTo('subscriber'),
    type: attr('string') // PILOT, ACTIVE, ON_HOLD

  });

  _exports.default = _default;
});