define("data-marketplace-ui/templates/components/service-errors", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Cx4SlZLU",
    "block": "{\"symbols\":[\"count\",\"error\"],\"statements\":[[10,\"div\"],[14,0,\"flex flex-row\"],[12],[2,\"\\n\"],[6,[37,5],[[30,[36,4],[[35,3]],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"details-view__status--error details-view__status text--label ml--small\"],[12],[2,\"\\n      \"],[1,[32,2]],[2,\" \\n\"],[6,[37,2],[[30,[36,1],[[30,[36,0],[[32,1],1],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"        (\"],[1,[32,1]],[2,\")\\n\"]],\"parameters\":[]}]]],[2,\"    \"],[13],[2,\"\\n\"]],\"parameters\":[1,2]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"eq\",\"not\",\"if\",\"groupedErrors\",\"-each-in\",\"each\"]}",
    "meta": {
      "moduleName": "data-marketplace-ui/templates/components/service-errors.hbs"
    }
  });

  _exports.default = _default;
});