define("data-marketplace-ui/templates/components/modal-popup", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "c1fiS6yC",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[10,\"div\"],[14,0,\"modal-backdrop\"],[12],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"modal-popup\"],[12],[2,\"\\n  \"],[18,1,null],[2,\"\\n  \"],[10,\"div\"],[14,0,\"close-button-align\"],[12],[2,\"\\n    \"],[11,\"button\"],[24,0,\"modal-close-button\"],[24,4,\"button\"],[4,[38,0],[\"click\",[32,0,[\"close\"]]],null],[12],[2,\"\\n      \"],[1,[30,[36,1],[\"clear\"],[[\"class\"],[\"icon\"]]]],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\\n\"]],\"hasEval\":false,\"upvars\":[\"on\",\"svg-jar\"]}",
    "meta": {
      "moduleName": "data-marketplace-ui/templates/components/modal-popup.hbs"
    }
  });

  _exports.default = _default;
});