define("data-marketplace-ui/router", ["exports", "data-marketplace-ui/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Router = Ember.Router.extend({
    router: Ember.inject.service(),
    metrics: Ember.inject.service(),
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL,
    didTransition: function didTransition() {
      this._super.apply(this, arguments);

      this.metrics.trackPage({
        page: this.router.currentRouteName,
        url: this.router.currentURL
      });
    }
  });
  Router.map(function () {
    this.route('authenticated', {
      path: ''
    }, function () {
      // routes for logged in users go here, include `resetNamespace: true`
      // this.route('overview', { resetNamespace: true });
      this.route('vehicles', {
        resetNamespace: true
      }, function () {
        this.route('vehicle', {
          resetNamespace: true,
          path: '/details'
        });
      });
      this.route('services', {
        resetNamespace: true
      });
      this.route('subscribers', {
        resetNamespace: true
      }, function () {
        this.route('subscriber-details', {
          resetNamespace: true,
          path: '/:subscriber_id'
        });
      });
    });
    this.route('sign-in');
    this.route('mfa-setup');
    this.route('mfa-verify');
    this.route('passwords', function () {
      this.route('forgot');
      this.route('reset');
      this.route('new');
    });
  });
  var _default = Router;
  _exports.default = _default;
});