define("data-marketplace-ui/templates/components/flag-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "6HEHSVOJ",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"flex flex-row items-center\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"flex\"],[12],[1,[30,[36,0],[[32,0,[\"flagIcon\"]]],null]],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"flex pl--small text--body-3\"],[12],[1,[32,0,[\"country\"]]],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"svg-jar\"]}",
    "meta": {
      "moduleName": "data-marketplace-ui/templates/components/flag-icon.hbs"
    }
  });

  _exports.default = _default;
});