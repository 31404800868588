define("data-marketplace-ui/serializers/vehicle", ["exports", "data-marketplace-ui/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    normalizeQueryResponse: function normalizeQueryResponse(store, primaryModelClass, payload, id, requestType) {
      var jsonApi = {
        data: payload.vehicles.map(function (vehicle) {
          return {
            id: vehicle.vin,
            type: 'vehicle',
            attributes: vehicle
          };
        })
      };
      return this._super(store, primaryModelClass, jsonApi, id, requestType);
    },
    normalizeFindRecordResponse: function normalizeFindRecordResponse(store, primaryModelClass, payload, id, requestType) {
      var normalizedPayload = {
        data: {
          id: payload.vin,
          type: 'vehicle',
          attributes: payload
        }
      };
      return this._super(store, primaryModelClass, normalizedPayload, id, requestType);
    }
  });

  _exports.default = _default;
});