define("data-marketplace-ui/components/service", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['service-box'],
    available: Ember.computed.alias('vehicleNumber'),
    palette: Ember.computed('available', function () {
      return this.available ? 'primary' : 'background';
    }),
    shade: Ember.computed('available', function () {
      return this.available ? 'light' : 'dark';
    })
  });

  _exports.default = _default;
});