define("data-marketplace-ui/components/forgot-password-form", ["exports", "data-marketplace-ui/components/cognito-form", "data-marketplace-ui/utils/validators"], function (_exports, _cognitoForm, _validators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @class ForgotPasswordForm
   * @augments {CognitoForm}
   * @description A form for initiating the reset password flow for a Cognito user.
   */
  var _default = _cognitoForm.default.extend({
    intl: Ember.inject.service(),

    /**
     * @public
     * @description The email address to send the verification code to.
     * @type {string}
     */
    email: null,

    /**
     * @public
     * @description Action called when the field is updated.
     * @type {action}
     */
    updateEmail: Ember.computed('email', {
      // reset notAuthorized variable
      set: function set() {
        this.notAuthorized = false;
      }
    }),

    /**
     * @private
     * @description Determines the visibility of the "Verification Code Sent" confirmation
     * @type {boolean}
     */
    showConfirmation: false,

    /**
     * @private
     * @description Determines the visibility of the "Verification Code Sent" confirmation
     * @type {boolean}
     */
    notAuthorized: false,

    /**
     * @private
     * @description Determines whether the form can be submitted based on field contents and errors.
     * @type {boolean}
     */
    submitDisabled: Ember.computed('email', 'emailErrors.[]', {
      get: function get() {
        return Ember.isEmpty(this.email) || !Ember.isEmpty(this.emailErrors);
      }
    }),

    /**
     * @private
     * @description Validation/error messages for the email field.
     * @type {string[]}
     */
    emailErrors: Ember.computed('email', {
      get: function get() {
        if (!this.email) {
          return [];
        }

        return [_validators.default.email(this.email, this.intl), _validators.default.emailNotAuthorized(this.notAuthorized, this.intl)].filter(Boolean);
      }
    }),

    /**
     * @private
     * @override
     * @description Displays the verification confirmation after the code is sent.
     */
    onSendVerificationCodeSuccess: function onSendVerificationCodeSuccess() {
      Ember.set(this, 'showConfirmation', true);
    },

    /**
     * @private
     * @override
     * @description Action taken when there is an error sending the verification code.
     */
    onSendVerificationCodeError: function onSendVerificationCodeError(error) {
      Ember.set(this, 'notAuthorized', error.code); // Refresh email field to call validations and display the error message

      var emailBK = this.email;
      Ember.set(this, 'email', '');
      Ember.set(this, 'email', emailBK); // eslint-disable-next-line no-console

      console.log(this.emailErrors);
    }
  });

  _exports.default = _default;
});