define("data-marketplace-ui/components/step-progress", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @class StepProgress
   * @description Progress indicator for multi-step flows
   */
  var _default = Ember.Component.extend({
    classNames: ['step-progress', 'flex flex-row pl--none'],
    classNameBindings: ['bgDark'],
    tagName: 'ul',

    /**
     * @public
     * @description Currently visible step in the process.
     * @type {number}
     */
    currentStep: null,

    /**
     * @public
     * @description Total number of steps in the process.
     * @type {number}
     */
    total: null
  });

  _exports.default = _default;
});