define("data-marketplace-ui/routes/authenticated/index", ["exports", "data-marketplace-ui/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    currentUser: Ember.inject.service(),
    beforeModel: function beforeModel() {
      if (this.currentUser.isAdmin) {
        this.transitionTo(_environment.default.APP.defaultAdminRoute);
      } else {
        this.transitionTo(_environment.default.APP.defaultRoute);
      }
    }
  });

  _exports.default = _default;
});