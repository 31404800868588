define("data-marketplace-ui/mirage/config", ["exports", "ember-cli-mirage/orm/collection", "lodash"], function (_exports, _collection, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;
  _exports.testConfig = testConfig;

  function sortCollection(modelName, collection, sort) {
    if (!sort) {
      return collection;
    }

    var direction = sort.charAt(0) === '-' ? 'desc' : 'asc';
    return Object.assign(new _collection.default(modelName), collection, {
      models: _lodash.default.orderBy(collection.models, [sort.replace('-', '')], [direction])
    });
  }

  function _default() {
    this.passthrough('https://cognito-idp.us-east-1.amazonaws.com');
    this.passthrough('https://cognito-idp.us-east-2.amazonaws.com');
    this.urlPrefix = '/'; // This endpoint isn't under the same namespace as the rest, so defining it here.

    this.get('mgmt/v2/subscribers/:subscriberId', function (schema, request) {
      return {
        subscriberId: request.params.subscriberId,
        name: 'Test Subscriber',
        owner: ['LA008'],
        type: 'UNTRUSTED_SUBSCRIBER_ADMIN',
        emailAddress: 'subscriber@example.com'
      };
    });
    this.post('/mgmt/v2/vehicles/enrollments/bulk', function (schema, request) {
      var orders = schema.orders,
          services = schema.services;
      var requestBody = request.requestBody;
      var reqJSON = JSON.parse(requestBody);
      var vinList = [];
      var productIds = [];
      var requestAction = 'CREATE';
      reqJSON.actions.forEach(function (action) {
        if (requestAction != action.action) {
          requestAction = action.action;
        }

        action.products.forEach(function (product) {
          productIds.push(product.productId);
          product.vinList.forEach(function (vin) {
            return vinList.push(vin);
          });
        });
      });
      var products = productIds.map(function (productId) {
        return services.find(productId);
      });
      orders.create({
        type: requestAction === 'CREATE' ? 'Enrollment' : 'Unenrollment',
        status: 'in progress',
        products: products.map(function (product) {
          return product.label;
        }),
        pending: vinList.uniq().length
      });
      return {
        status: 'success'
      };
    });
    this.namespace = 'mgmt/v2/ui/subscribers/*'; // make this `/api`, for example, if your API is namespaced

    this.get('/vehicles', function (schema, _ref) {
      var qp = _ref.queryParams;
      var sort = qp.sort,
          status = qp.status,
          services = qp.services,
          search = qp.q;
      var vehicles = schema.vehicles.all();

      if (status) {
        vehicles = vehicles.filter(function (v) {
          return v.status === status;
        });
      }

      if (sort) {
        vehicles = sortCollection('vehicle', vehicles, sort);
      }

      if (services) {
        vehicles = vehicles.filter(function (v) {
          return v.services.some(function (serviceId) {
            return services.includes(serviceId.toString());
          });
        });
      }

      if (search) {
        vehicles = vehicles.filter(function (v) {
          return v.vin.toLowerCase().includes(search.toLowerCase());
        });
      }

      return this.serialize(vehicles);
    });
    this.get('/vehicle', function (schema, request) {
      var vehicle = schema.vehicles.findBy({
        vin: request.requestHeaders.vin
      });
      return this.serialize(vehicle, 'single-vehicle');
    });
    this.get('/orders', function (schema, _ref2) {
      var qp = _ref2.queryParams;
      var sort = qp.sort,
          status = qp.status,
          services = qp.services,
          offset = qp.offset;
      var orders = schema.orders.all();

      if (status) {
        orders = orders.filter(function (v) {
          return v.status === status.split('-').join(' ');
        });
      }

      if (sort) {
        orders = sortCollection('order', orders, sort);
      }

      if (services) {
        orders = orders.filter(function (v) {
          return v.services.some(function (serviceId) {
            return services.includes(serviceId.toString());
          });
        });
      }

      if (offset && offset != '0') {
        orders.models.clear();
      }

      return this.serialize(orders);
    });
    this.get('/vehicles-count', function (schema) {
      return this.serialize(schema.vehiclesCount.first());
    });
    this.get('/orders/:id');
    this.get('/products', function (schema) {
      return this.serialize(schema.services.all(), 'service');
    });
  }

  function testConfig() {
    // this = mirage server
    // TODO: make sure the vehicle counts endpoint ALWAYS returns an object
    // with the counts zero'd out if they don't exist.
    this.loadFixtures('vehicles-count');
  }
});