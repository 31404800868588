define("data-marketplace-ui/components/csv-uploader", ["exports", "@loom/ember-buttons/components/button-basic"], function (_exports, _buttonBasic) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _buttonBasic.default.extend({
    flashMessages: Ember.inject.service(),
    intl: Ember.inject.service(),
    store: Ember.inject.service(),
    tagName: 'label',
    classNames: ['btn--small'],
    attributeBindings: ['data-test-upload-csv'],
    disabled: false,
    handleUpload: null,
    mainTask: function mainTask(fileInput) {
      if (this.handleUpload) {
        this.handleUpload(Array.from(fileInput.files));
      }
    },
    click: function click() {} // override parent

  });

  _exports.default = _default;
});