define("data-marketplace-ui/templates/passwords/forgot", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "xUn4pNEn",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"flex flex-row w--screen h--screen\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"brand-container bg__background--light flex items-center\"],[12],[2,\"\\n    \"],[8,\"logo\",[],[[],[]],null],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"image-placeholder\"],[12],[13],[2,\"\\n  \"],[10,\"section\"],[14,0,\"flex flex-1 flex-column items-end justify-center bg__background--light p--semi-large\"],[12],[2,\"\\n    \"],[8,\"forgot-password-form\",[],[[\"@email\",\"@updateEmail\"],[[34,0,[\"email\"]],[30,[36,2],[[32,0],[30,[36,1],[[35,0,[\"email\"]]],null]],[[\"value\"],[\"target.value\"]]]]],null],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"passwords\",\"mut\",\"action\"]}",
    "meta": {
      "moduleName": "data-marketplace-ui/templates/passwords/forgot.hbs"
    }
  });

  _exports.default = _default;
});