define("data-marketplace-ui/templates/components/svg/svg-car", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "3wetPDUf",
    "block": "{\"symbols\":[],\"statements\":[[2,\"\\n\"],[10,\"g\"],[14,\"stroke\",\"none\"],[14,\"stroke-width\",\"1\"],[14,\"fill\",\"none\"],[14,\"fill-rule\",\"evenodd\"],[12],[2,\"\\n  \"],[10,\"path\"],[14,\"fill\",\"#FFFFFF\"],[14,\"fill-rule\",\"nonzero\"],[14,\"d\",\"M18.5046957,4.98365217 C18.309913,4.40904348 17.7645217,4 17.1217391,4 L6.40869565,4 C5.76591304,4 5.23026087,4.40904348 5.02573913,4.98365217 L3,10.8173913 L3,18.6086957 C3,19.1443478 3.43826087,19.5826087 3.97391304,19.5826087 L4.94782609,19.5826087 C5.48347826,19.5826087 5.92173913,19.1443478 5.92173913,18.6086957 L5.92173913,17.6347826 L17.6086957,17.6347826 L17.6086957,18.6086957 C17.6086957,19.1443478 18.0469565,19.5826087 18.5826087,19.5826087 L19.5565217,19.5826087 C20.0921739,19.5826087 20.5304348,19.1443478 20.5304348,18.6086957 L20.5304348,10.8173913 L18.5046957,4.98365217 Z M6.40869565,14.7130435 C5.60034783,14.7130435 4.94782609,14.0605217 4.94782609,13.2521739 C4.94782609,12.4438261 5.60034783,11.7913043 6.40869565,11.7913043 C7.21704348,11.7913043 7.86956522,12.4438261 7.86956522,13.2521739 C7.86956522,14.0605217 7.21704348,14.7130435 6.40869565,14.7130435 Z M17.1217391,14.7130435 C16.3133913,14.7130435 15.6608696,14.0605217 15.6608696,13.2521739 C15.6608696,12.4438261 16.3133913,11.7913043 17.1217391,11.7913043 C17.930087,11.7913043 18.5826087,12.4438261 18.5826087,13.2521739 C18.5826087,14.0605217 17.930087,14.7130435 17.1217391,14.7130435 Z M4.94782609,9.84347826 L6.40869565,5.46086957 L17.1217391,5.46086957 L18.5826087,9.84347826 L4.94782609,9.84347826 Z\"],[12],[13],[2,\"\\n  \"],[10,\"polygon\"],[14,\"points\",\"0 0 23.373913 0 23.373913 23.373913 0 23.373913\"],[12],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "data-marketplace-ui/templates/components/svg/svg-car.hbs"
    }
  });

  _exports.default = _default;
});