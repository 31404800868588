define("data-marketplace-ui/components/service-errors", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var ERROR_STATUS = 'Inactive';
  var UNKNOWN_ERROR = 'Error Unspecified';

  var _default = Ember.Component.extend({
    classNames: ['service-errors'],

    /**
     * @description Returns an array of errors grouped and counted by name
     */
    groupedErrors: Ember.computed('errors', {
      get: function get() {
        var errorsGrouped = {};
        this.services.filter(function (service) {
          return service.status == ERROR_STATUS;
        }).forEach(function (service) {
          var errorName = service.errorStatus ? service.errorStatus : UNKNOWN_ERROR;
          errorsGrouped[errorName] = errorsGrouped[errorName] + 1 || 1;
        });
        return errorsGrouped;
      }
    })
  });

  _exports.default = _default;
});