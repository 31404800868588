define("data-marketplace-ui/components/form-confirmation/reset-success", ["exports", "data-marketplace-ui/components/form-confirmation"], function (_exports, _formConfirmation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @class FormConfirmation::VerificationCode
   * @augments FormConfirmation
   * @description Confirms that a verification code was sent successfully.
   */
  var _default = _formConfirmation.default.extend({
    /**
     * @private
     * @override
     */
    backdrop: true
  });

  _exports.default = _default;
});