define("data-marketplace-ui/mirage/helpers/create-services", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = createServices;

  /**
   * Helper for creating services with the product ids that currently exist
   * since we use those ids when displaying service icons.
   */
  function createServices(server) {
    var PRODUCT_ID_MAP = {
      Telemetry: '7014f848-e704-41f0-aa7a-1ed2de3fe094',
      'Driver Score': 'df42edba-46e9-4681-b7d7-495bc60f1a86',
      'Event Notification': '275f6329-16d0-480b-980b-58aa88967f5a',
      'Non Automatic Collision Notification': '4f4da4c2-3206-4fab-920d-d341a23772ca'
    };
    ['Telemetry', 'Driver Score', 'Event Notification', 'Non Automatic Collision Notification'].forEach(function (service) {
      server.create('service', {
        id: PRODUCT_ID_MAP[service],
        name: service.replace(/\W/g, '_').toUpperCase(),
        label: service,
        description: "This service provides ".concat(service, " data"),
        uiEnabled: true
      });
    });
  }
});